import React, { Component } from 'react'
import MuiAlert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar'

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import Checkbox from '@material-ui/core/Checkbox';

import Modal from '@material-ui/core/Modal';

import ReactJson from 'react-json-view'
import { CSVLink, CSVDownload } from "react-csv";


function checkIsValidDomain(domain) {
  var re = new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/i);
  return domain.match(re) ? true : false;
}

function csvFields(results){
  if (!results || !Array.isArray(results)){ return []}

  return results.map(result=>{
    if (!result){return;}
    try{
      const el={
        orgName : result.orgName??'',
        date  : result.date,
        victim  : result.victim,
        victimSector  : result.victimSector,
        victimCountry  : result.victimCountry,
        victimRevenue      :  ( Object.keys(result?.victimDetails).length>0? result?.victimDetails?.metrics?.estimatedAnnualRevenue : ''),
        victimEmployees    :  ( Object.keys(result?.victimDetails).length>0? result?.victimDetails?.metrics?.employeesRange : ''),
        victimLegalName    :  ( Object.keys(result?.victimDetails).length>0? result?.victimDetails?.legalName : ''),
        victimLink   : result.victimLink,
        actor        : result.actor,
        annTitle     : result.annTitle,
        annLink      : result.annLink,
        annDataTypes      : (Array.isArray(result.annDataTypes)?result.annDataTypes:[]).join(','),
        annDescription      : result.annDescription,
        confidence: result.confidence
      };
      return el;  
    } catch (err){
      console.log(`[!] error exporting CSV data (${err}):`,result);
      return;
    }
  }).filter(x=>x);
}

class Query extends Component {

  constructor(props) {
    super(props)
    this.state = {
      searchType: window.localStorage.getItem('query_searchType') ?? 'keyword',
      keyword: window.localStorage.getItem('query_keyword') ?? '',
      domainNames: JSON.parse(window.localStorage.getItem('query_domainNames') ?? "[]"),
      extendedMode: Boolean(window.localStorage.getItem('query_extendedMode') ?? false),
      extendedMode: Boolean(window.localStorage.getItem('query_extendedModeK') ?? false),
      dataset: window.localStorage.getItem('query_dataset') ?? 'ext',
      maxResults: parseInt(window.localStorage.getItem('query_maxResults') ?? 1),
      errorOpen:false,
      errorMessage:``,
      results: [],
      waitingResults: false
    }
  }

  componentDidMount() {

  }

  setSearchType = (searchType) => {
    window.localStorage.setItem('query_searchType', searchType);
    this.setState({ searchType: searchType });
  }
  setDomainName = (domains) => {
    const doms = String(domains).split(',').map(d => d.trim());
    window.localStorage.setItem('query_domainNames', JSON.stringify(doms));
    this.setState({ domainNames: doms });
  }
  setKeyword = (keyword) => {
    window.localStorage.setItem('query_keyword', keyword);
    this.setState({ keyword: keyword });
  }
  setExtendedMode = (mode) => {
    window.localStorage.setItem('query_extendedMode', Boolean(mode));
    this.setState({ extendedMode: Boolean(mode) });
  }
  setExtendedModeK = (mode) => {
    window.localStorage.setItem('query_extendedModeK', Boolean(mode));
    this.setState({ extendedModeK: Boolean(mode) });
  }
  setDataset = (dataset) => {
    window.localStorage.setItem('query_dataset', dataset);

    this.setState({ dataset: dataset });
  }
  setMaxResults = (value) => {
    window.localStorage.setItem('query_maxResults', value);

    this.setState({ maxResults: value });
  }

  keyPress= (e)=>{
    if(e.keyCode == 13){
       console.log('value', e.target.value);
       if (!this.state.waitingResults){
          return this.handleSearch();
       }
    }
 }

  handleSearch = async () => {

    this.setState({
      waitingResults: true,
      errorOpen:false,
      errorMessage:``
    });

    const apiEndpoint = window.localStorage.getItem('apiEndpoint')?? "https://api.eu-ep1.doubleextortion.com/v1";
    const apiKey = window.localStorage.getItem('apiKey');
    const token = window.sessionStorage.getItem('token');
    const body = {};
    const headers = {};

    const searchType = this.state.searchType;

    headers['Authorization'] = token;
    headers['Content-Type'] = 'application/json';
    headers['X-Api-Key'] = apiKey;

    try {

      if ('keyword' == searchType) {
        body.keyw = this.state.keyword;
        body.extended = Boolean(this.state.extendedModeK ?? false);
      }
      if ('domains' == searchType) {
        body.domain = this.state.domainNames.filter(x => checkIsValidDomain(x));
        body.extended = Boolean(this.state.extendedMode ?? false);
      }

      body.dset = this.state.dataset;
      body.maxres = this.state.maxResults;

      console.log(`[-] querying to "${`${apiEndpoint}/dbtr/search`}":`, body)
      fetch(`${apiEndpoint}/dbtr/search`, {
        method: 'POST',
        headers: { ...headers },
        body: JSON.stringify(body),
        mode: 'cors'
      })
        .then(async (response) => {

          console.log(`[+] results retrieved:`, response)

          const data = await response.json();
          console.debug(`[+] data retrieved:`, data)

          this.setState({
            waitingResults: false,
            results: Array.isArray(data)? data : [data]
          });

        })
        .catch(async (err) => {
          console.log(`[!] results error:`, err)
          this.setState({
            waitingResults: false,
            results: [{ Error: err?.message }],
            errorOpen:true,
            errorMessage:`Error while retrieving data: ${err.message}`
          });

        });

    } catch (err) {
      console.error(`[!] Something wrong while retrieving data:`, err);
      this.setState({
        waitingResults: false,
        errorOpen:true,
        errorMessage:`Error while retrieving data: ${err.message}`
      });

    }


    // this.setState({
    //   results: [
    //     { id: 1, title: 'Sample Result 1', content: 'This is the content of sample result 1.' },
    //     { id: 2, title: 'Sample Result 2', content: 'This is the content of sample result 2.' },
    //   ]
    // });
  };

  handleSelect = async(e)=>{
    if (['hashid','breachID'].indexOf(e.name) == -1) return;

      
    const apiEndpoint = window.localStorage.getItem('apiEndpoint')?? "https://api.eu-ep1.doubleextortion.com/v1";
    const apiKey = window.localStorage.getItem('apiKey');
    const token = window.sessionStorage.getItem('token');
    const body = {};
    const headers = {};

    headers['Authorization'] = token;
    //headers['Content-Type'] = 'application/json';
    headers['X-Api-Key'] = apiKey;
    headers['Accept']='image/png, text/html, application/pdf, application/octet-stream'

    try {
      console.log(`[-] querying to "${`${apiEndpoint}/dbtr/rawdata`}": ${e.value}`)
      fetch(`${apiEndpoint}/dbtr/rawdata?hashid=${e.value}&fmt=png`, {
        method: 'GET',
        headers: { ...headers },
        mode: 'cors'
      })
      .then(async (response) => {
          console.log(`[+] results retrieved:`, response)
          if (!response.ok){
            return this.setState({
              waitingResults: false,
              errorOpen:true,
              errorMessage:`Screenshot for "${e.value}" not available`,
              showSideImage: false,
              sideImage: 'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAwAB/ZSZ4QAAAABJRU5ErkJggg=='
            });
          }
          const blob = await response.blob();
          const dataB64 = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
              const base64data = reader.result.split(',')[1];
              resolve(base64data);
            };
            reader.onerror = reject;
          });
          this.setState({
            waitingResults: false,
            errorOpen:false,
            showSideImage: true,
            sideImage: dataB64
          });
        })
      .catch(async (err) => {
          console.log(`[!] results error:`, err)
          this.setState({
            waitingResults: false,
            results: [{ Error: err?.message }],
            errorOpen:true,
            errorMessage:`Error while retrieving data: ${err.message}`,
            sideImage:'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAwAB/ZSZ4QAAAABJRU5ErkJggg=='
          });
      });
      this.setState({ waitingResults: true, errorOpen:false });
    } catch(e){
        console.error(`[!] Something wrong while retrieving data:`, err);
        this.setState({
          waitingResults: false,
          errorOpen:true,
          errorMessage:`Error while retrieving data: ${err.message}`,
          sideImage:'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAwAB/ZSZ4QAAAABJRU5ErkJggg=='
        });
    }

  }
  

  render() {
    const token = window.sessionStorage.getItem('token') || null
    const apiKey = window.sessionStorage.getItem('apikey') || null
    const keyword = this.state.keyword;
    const searchType = this.state.searchType;
    const domainNames = this.state.domainNames;
    const extendedMode = this.state.extendedMode;
    const extendedModeK = this.state.extendedModeK;
    const dataset = this.state.dataset;
    const results = this.state.results;
    const maxResults = this.state.maxResults;

    const sideImage = this.state.sideImage;
    const showSideImage = this.state.showSideImage ?? false;

    let enabled_gourps=[]
    try{
      const access_token_p = JSON.parse(window.atob(window.sessionStorage.getItem('access_token').split('.')[1], 'base64').toString());
      enabled_gourps = access_token_p["cognito:groups"]  
    } catch (ex){
   
    }

    return (
      <div className="content">

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={this.state.waitingResults}
          onClose={() => { }}
          message='Retrieving Results...'
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={this.state.errorOpen}
          onClose={() => { }} >
           <MuiAlert elevation={6} variant="filled" severity="error">{this.state.errorMessage}</MuiAlert>
        </Snackbar>
        <div className="container-fluid" >

          <div className="row">
            <div>
              <br />
              <h3 className="card-subtitle h3 text-white">Company Query</h3>
            </div>
            <div className="card-body text-white text-justify">
              Company Query is the user interface of the DEP Platform's <b>Search API</b>. This powerful Search API empowers you to search for companies and organizations entangled in cyber events. The Company Query interface supports searches based on keywords, legal names, or domains associated with the businesses involved.
            </div>
          </div>

          <div className="row">
            <div>
              <br />
              <h3 className="card-subtitle h6 text-white">Company Query Parameters</h3>
            </div>
          </div>
          <hr style={{ backgroundColor: '#a05b5b', width: '100%' }} ></hr>
          <div className="row">

            <div className="col-sm">
              <div className="message text-white">

                <div className='row align-items-center'>
                  <div className='col-sm'>Query Type:</div>
                  <div className='col-sm'>

                    <Select
                      style={{ backgroundColor: 'white', width: '100%', textAlign: 'center' }}
                      labelId="qType"
                      id="qType-selector"
                      value={searchType}
                      onChange={(e) => this.setSearchType(e.target.value)} >
                      <MenuItem value='keyword'>Keyword</MenuItem>
                      <MenuItem value='domains'>Domain Names</MenuItem>
                    </Select>

                  </div>
                </div>

                <div className='row align-items-center'>
                  <div className='col-sm'>Dataset:</div>
                  <div className='col-sm'>

                    <Select
                      style={{ backgroundColor: 'white', width: '100%', textAlign: 'center' }}
                      labelId="dSet"
                      id="qSet-selector"
                      value={dataset}
                      onChange={(e) => this.setDataset(e.target.value)} >
                       {( enabled_gourps.indexOf('extortions-users')!=-1  || enabled_gourps.indexOf('full-access')!=-1?<MenuItem value='ext'>Extortions</MenuItem>:<div></div>)}
                       {( enabled_gourps.indexOf('opennews-users')!=-1  || enabled_gourps.indexOf('full-access')!=-1?<MenuItem value='nws'>OpenNews</MenuItem>:<div></div>)}
                       {( enabled_gourps.indexOf('privacy-users')!=-1  || enabled_gourps.indexOf('full-access')!=-1?<MenuItem value='prv'>Privacy</MenuItem>:<div></div>)}
                       {( enabled_gourps.indexOf('vandalism-users')!=-1  || enabled_gourps.indexOf('full-access')!=-1?<MenuItem value='vnd'>Vandalism</MenuItem>:<div></div>)}
                       {( enabled_gourps.indexOf('forum-users')!=-1  || enabled_gourps.indexOf('full-access')!=-1?<MenuItem value='frm'>Underground</MenuItem>:<div></div>)}
                       {( enabled_gourps.indexOf('ddos-users')!=-1  || enabled_gourps.indexOf('full-access')!=-1?<MenuItem value='dds'>DDoS</MenuItem>:<div></div>)}

                    </Select>

                  </div>
                </div>

                <div className='row align-items-center'>
                  <div className='col-sm'>Max Results:</div>
                  <div className='col-sm'>
                    <Slider
                      value={maxResults}
                      aria-labelledby="discrete-slider"
                      valueLabelDisplay="auto"
                      step={1}
                      
                      min={1}
                      max={10}
                      style={{ color: '#a05b5b' }}
                      onChange={(e, value) => this.setMaxResults(value)}
                    />
                  </div>
                </div>

              </div>
            </div>
            <div className="col-sm">
              <div className="message text-white">

                {searchType === 'keyword' && (
                  <div>
                    <div className="row align-items-center">
                      <div className="col">
                        <span className="text-white">Keyword:</span><br />
                        <TextField style={{
                          backgroundColor: 'white', border: '2px solid #a05b5b', width: '100%',
                          '&:hover': { border: '5px solid red' },
                        }} id="keyword-text" label="" variant="outlined" value={keyword}  onKeyDown={this.keyPress} onChange={(e) => this.setKeyword(e.target.value)} />

                      </div>
                    </div>
                    <br />

                    <div className="row align-items-center">
                      <div className="col">
                        <Tooltip title="Switch between strict phrase match and word match (could lead to false matches)"><span className="text-white">Extended Mode:</span></Tooltip>
                      </div>
                      <div className="col">
                        <Checkbox
                          size="medium"
                          style={{ color: 'white' }}
                          inputProps={{ 'aria-label': 'checkbox with small size' }}
                          checked={extendedModeK} onChange={(e) => this.setExtendedModeK(e.target.checked)}
                        />

                      </div>
                    </div>
                  </div>
                )}
                {searchType === 'domains' && (
                  <div>
                    <div className="row align-items-center">
                      <div className="col">
                        <Tooltip title="Comma separated list of domain names"><span className="text-white">Domain Names:</span></Tooltip>
                        <TextField style={{
                          backgroundColor: 'white', border: '2px solid #a05b5b', width: '100%',
                          '&:hover': { border: '5px solid red' },
                        }} id="domains-text" label="" variant="outlined" value={domainNames} onKeyDown={this.keyPress}  onChange={(e) => this.setDomainName(e.target.value)} />
                      </div>
                    </div>
                    <br />
                    <div className="row align-items-center">
                      <div className="col">
                        <Tooltip title="Enable partial domain matching (could lead to false matches)"><span className="text-white">Extended Mode:</span></Tooltip>
                      </div>
                      <div className="col">
                        <Checkbox
                          size="medium"
                          style={{ color: 'white' }}
                          inputProps={{ 'aria-label': 'checkbox with small size' }}
                          checked={extendedMode} onChange={(e) => this.setExtendedMode(e.target.checked)}
                        />

                      </div>
                    </div>

                  </div>
                )}
              </div>
            </div>
          </div>


          <div className="row justify-content-center">
            <div className="col ">
              <br />
              <button disabled={this.state.waitingResults} style={{ width: '100%' }} className="button-53 text-uppercase" onClick={this.handleSearch}>Search</button>
            </div>
          </div>


          <br />

          <div className="row">
            <div>
              <br />
              <h3 className="card-subtitle h6 text-white">Results</h3>
            </div>
          </div>
          <hr style={{ backgroundColor: '#a05b5b', width: '100%' }} ></hr>
          <div className="row ">
              <small className='text-white'>Export Results in CSV </small> <CSVLink className="link-dark" filename={`dep_search-${dataset}-export_${new Date().toISOString()}.csv`} 
              data={csvFields(results)}><i className="nc-icon nc-cloud-download-93"></i></CSVLink>
          </div>
          <br/>
          <div className="row">
            <div className='col'>
              <div className='card'>

                    
                    <ReactJson name={false} iconStyle='circle' collapsed={2} displayDataTypes={false} src={results} 
                    onSelect={(e)=>this.handleSelect(e)}/>
                  
                   <Modal
                    style={{
                      top: 0,left: 0,  right: 0,  bottom: 0,
                      animation: showSideImage?'fadeIn 0.3s ease-in-out':'fadeOutImage 0.5s ease-in-out forwards',
                      
                      display: 'flex',
                    }}
                    open={showSideImage}
                    onClose={()=>this.setState({showSideImage:false})}
                    className=""
                    overlayClassName=""
                  >
                    <img style={{
                      top: `50%`,
                      left: `50%`,
                      transform: `translate(-50%, -50%)`,
                      position:'relative',
                      padding: '20px',
                      maxWidth: '90%',
                      maxHeight: '90%',
                      overflow: 'auto',
                      borderRadius: '10px',
                      animation: showSideImage?'fadeIn 0.3s ease-in-out':'fadeOutImage 0.5s ease-in-out forwards',
                      width: '70%',  height:'auto', cursor: 'pointer', }} src={`data:image/png;base64,${sideImage}`}  onClick={(e)=>this.setState({showSideImage:false})}/>
                    </Modal>
                  


              </div>
            </div>
          </div>


        </div>
      </div >
    )
  }
}

export default Query
