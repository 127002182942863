import React, { Component } from 'react'
import Tooltip from '@material-ui/core/Tooltip';

import { NavLink, Link } from 'react-router-dom'


class Sidebar extends Component {


  handleLogoutClick = (e) => {
    ['token', 'refresh_token', 'id_token', 'access_token'].forEach(t => {try{window.sessionStorage.removeItem(t)}catch(e){ console.log(`[!] error`,e); } });
    //['apiEndpoint', 'apiKey'].forEach(t => { try{window.localStorage.removeItem(t)}catch(e){console.log(`[!] error`,e);} });
    this.setState({ logoutRequest: true })

    window.location.replace('#/land');
    // window.location.reload(false);

  }

  render() {
    let token = window.sessionStorage.getItem('token') || null
    let refresh_token = window.sessionStorage.getItem('refresh_token') || null
    let id_token = window.sessionStorage.getItem('id_token') || null
    let enabled_gourps=[]
    try{
      const access_token_p = JSON.parse(window.atob(window.sessionStorage.getItem('access_token').split('.')[1], 'base64').toString());
      enabled_gourps = access_token_p["cognito:groups"]  
    } catch (ex){
   
    }
    

    return (
      <div className="sidebar" data-color="double" data-image="/assets/img/side1.jpg">

        <div className="sidebar-wrapper">

          <div className="logo">
            <a href='https://doubleextortion.com/' target="_blank" className="simple-text">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-sm-2 " ><img src="logow.svg" width="32" alt="Double Extortion" /></div>
                  <div className="col-sm-9"><span className="simple-text" style={{ marginLeft: '20px', lineHeight:1, textAlign: 'left' }}>Digital Intelligence Lab</span></div>
                </div>
              </div>
            </a>
          </div>



          <ul className="nav">

            <li className="nav-item">
              <a className="nav-link" href='https://doubleextortion.com/blog'>
                <i className="nc-icon nc-bulb-63"></i>
                <p className="">Blog</p>
              </a>
            </li>
            {(
              token ?
              <li className="nav-item">
              <hr style={{
                    backgroundColor: '#a05b5b',
                    width: '100%',
                    height: 0.5
              }} />
              {( enabled_gourps.indexOf('stats-users')!=-1  || enabled_gourps.indexOf('full-access')!=-1? 
                  <NavLink className="nav-link" to='/privatedashboard'>
                  <i className="nc-icon nc-chart-pie-36"></i>
                  <p className="">Statistics</p>
                </NavLink>
              :
                <Tooltip title={`Unavailable for your account: you need the "Stats API" subscription`}>
                  <div className="nav-link" >
                  <i style={{color:'#787171'}} className="nc-icon nc-chart-pie-36 "></i>
                  <p style={{color:'#787171'}} className="">Statistics</p>
                </div>
                </Tooltip>
              )}
            </li>
            :<li className="nav-item">
              <NavLink className="nav-link" to='/dashboard'>
                <i className="nc-icon nc-chart-pie-36"></i>
                <p className="">Dashboard</p>
              </NavLink>
            </li>
            )}
            {(
              token ?

              
              <li className="nav-item">
              {( enabled_gourps.indexOf('plist-users')!=-1 || enabled_gourps.indexOf('full-access')!=-1?   
                <NavLink className="nav-link" to='/privatedatatable'>
                  <i className="nc-icon nc-paper-2"></i>
                  <p className="">Private Data Table</p>
                </NavLink>
                :
                <Tooltip title={`Unavailable for your account: you need the "Privlist API" subscription`}>
                <div className="nav-link">
                  <i  style={{color:'#787171'}} className="nc-icon nc-paper-2"></i>
                  <p  style={{color:'#787171'}} className="">Private Data Table</p>
                </div>
                </Tooltip>
              )}
             </li>
              :<li className="nav-item">
              <NavLink className="nav-link" to='/datatable'>
                <i className="nc-icon nc-paper-2"></i>
                <p className="">Public Data Table</p>
              </NavLink>
              </li>
            )}

            {(
              token ?
              
                <li className="nav-item">
                  <hr style={{
                    backgroundColor: '#a05b5b',
                    width: '100%',
                    height: 0.5
                  }} />
                  <NavLink className="nav-link" to='/profile'>
                    <i className="nc-icon nc-badge"></i>
                    <p className="">Profile</p>
                  </NavLink>
                  <hr style={{
                    backgroundColor: '#a05b5b',
                    width: '100%',
                    height: 0.5
                  }} />
                </li>
                :
                <li className="nav-item">
                   <hr style={{
                    backgroundColor: '#a05b5b',
                    width: '100%',
                    height: 0.5
                  }} />
                  <a className="nav-link" href={`https://auth.eu-ep1.doubleextortion.com/login?client_id=49g8542gda2lstovq74aii323t&response_type=token&scope=email+openid+phone+profile&redirect_uri=${(`${window.location.protocol}//${window.location.host}/`)}`}>
                    <i className="nc-icon nc-single-02"></i>
                    <p className="">Log In</p>
                  </a>
                </li>
            )}
            {(
              token ?
                <li className="nav-item">
                   {( enabled_gourps.indexOf('search-users')!=-1  || enabled_gourps.indexOf('full-access')!=-1? 
                  <NavLink className="nav-link" to='/query'>
                    <i className="nc-icon nc-zoom-split"></i>
                    <p className="">Query</p>
                  </NavLink>
                  :
                  <Tooltip title={`Unavailable for your account: you need the "Search API" subscription`}>
                  <div className="nav-link">
                    <i style={{color:'#787171'}} className="nc-icon nc-zoom-split"></i>
                    <p style={{color:'#787171'}} className="">Query</p>
                  </div>
                  </Tooltip>
                   )}
                </li>
                :
                <span />
            )}
            {(
              token ?
                <li className="nav-item">
                   {( enabled_gourps.indexOf('search-users')!=-1  || enabled_gourps.indexOf('full-access')!=-1? 
                  <NavLink className="nav-link" to='/querybatch'>
                    <i className="nc-icon nc-delivery-fast"></i>
                    <p className="">Query Batch</p>
                  </NavLink>
                  :
                  <Tooltip title={`Unavailable for your account: you need the "Search API" subscription`}>
                  <div className="nav-link">
                    <i style={{color:'#787171'}} className="nc-icon nc-delivery-fast"></i>
                    <p style={{color:'#787171'}} className="">Query Batch</p>
                  </div>
                  </Tooltip>
                   )}
                </li>
                :
                <span />
            )}
            {(
              token ?
                <li className="nav-item">
                   {( enabled_gourps.indexOf('ask-users')!=-1  || enabled_gourps.indexOf('full-access')!=-1? 

                  <NavLink className="nav-link" to='/ask'>
                    <i className="nc-icon nc-audio-92"></i>
                    <p className="">Ask</p>
                  </NavLink>
                  :
                  <Tooltip title={`Unavailable for your account: you need the "Ask API" subscription`}>
                  <div className="nav-link">
                    <i style={{color:'#787171'}} className="nc-icon nc-audio-92"></i>
                    <p style={{color:'#787171'}} className="">Ask</p>
                  </div>
                  </Tooltip>
                   )}
                </li>
                :
                <span />
            )}
                        {(
              token ?
                <li className="nav-item">
                   {( enabled_gourps.indexOf('report-users')!=-1 || enabled_gourps.indexOf('full-access')!=-1? 
                  <NavLink className="nav-link" to='/report'>
                    <i className="nc-icon nc-single-copy-04"></i>
                    <p className="">Report</p>
                  </NavLink>
                  :
                  <Tooltip title={`Unavailable for your account: you need the "Report API" subscription`}>
                  <div className="nav-link" >
                    <i style={{color:'#787171'}} className="nc-icon nc-single-copy-04"></i>
                    <p style={{color:'#787171'}} className="">Report</p>
                  </div>
                  </Tooltip>
                  )}
                </li>
                :
                <span />
            )}
            {(
              token ?

                <li className="nav-item">
                  <hr style={{
                    backgroundColor: '#a05b5b',
                    width: '100%',
                    height: 0.5
                  }} />
                  <div className="nav-link" onClick={this.handleLogoutClick }  to='/land' >
                    <i className="nc-icon nc-button-power"></i>
                    <p className="">Logout</p>
                  </div>
                </li>
                : <span />
            )}

          </ul>
        </div>
      </div>
    )
    /*
                <li className="nav-item">
              <NavLink className="nav-link" to='/about'>
                <i className="nc-icon nc-tap-01"></i>
                <p className="">About</p>
              </NavLink>
            </li>

              <div className="dropdown-divider"></div>

            <li className="nav-item">
              <a className="nav-link" target="_blank" href="https://www.buymeacoffee.com/doubleextortion" >
                <img src="bmc.svg"  width="32" alt="Buy me a coffee" />
                <p className="">Buy me a coffee</p>
                </a>
            </li> 
    */
  }
}

export default Sidebar

