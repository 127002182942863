import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
//import Button from '@mui/material/Button';


class Navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.state.intro_open = true;
  }

  handleLogoutClick = (e) => {
    ['token', 'refresh_token', 'id_token', 'access_token'].forEach(t => {try{window.sessionStorage.removeItem(t)}catch(e){ console.log(`[!] error`,e); } });
    ['apiEndpoint', 'apiKey'].forEach(t => { try{window.localStorage.removeItem(t)}catch(e){console.log(`[!] error`,e);} });
    this.setState({ logoutRequest: true })

    window.location.replace('#/land');
    // window.location.reload(false);

  }

  render() {

    //let values = queryString.parse(window.location.href.split('?')[1]||"")
    //console.log("valuses")
    //console.log(window.location.href.split('?')[1]||"")
    //console.log(values) // "top"
    let token = window.sessionStorage.getItem('token') || null
    let refresh_token = window.sessionStorage.getItem('refresh_token') || null
    let id_token = window.sessionStorage.getItem('id_token') || null

    let access_token_p;
    let expirationTime;
    let enabled_gourps;
    const logged=token && token!='';;
    try {
      access_token_p = JSON.parse(window.atob(window.sessionStorage.getItem('access_token').split('.')[1], 'base64').toString());
      expirationTime = new Date(access_token_p.exp * 1000).toLocaleString()
      enabled_gourps = access_token_p["cognito:groups"]
    } catch (ex) { }

    const accessValid = new Date(expirationTime?? 'N/A') > new Date();
    return (
      <nav className="navbar-expand-lg" color-on-scroll="500">

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={!accessValid && logged}
          onClose={() => { }} >
          <MuiAlert elevation={6} variant="filled" severity="error">Access Expired! Logoout and then Login back again.</MuiAlert>
        </Snackbar>

        <div className="container-fluid" >

          <div className="row align-items-center justify-content-center">
            <h1 className="text-white text-center"><b></b>DE PLATFORM</h1> <center><img className="d-flex flex-wrap mt-3" style={{marginLeft:10, verticalAlign: 'middle'}} src="logow.svg" width="34" alt="DEP Platform" /> </center>
          </div>

          <div className="row align-items-center justify-content-center d-block d-sm-none">

            <ul className="nav justify-content-center">

              <li className="nav-item ">
                <NavLink className="nav-link" to='/land'>
                  <i className="nc-icon nc-spaceship"></i>
                </NavLink>
              </li>
              <li className="nav-item ">
                <NavLink className="nav-link" to='/dashboard'>
                  <i className="nc-icon nc-chart-pie-36"></i>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to='/datatable'>
                  <i className="nc-icon nc-paper-2"></i>
                </NavLink>
              </li>
              {(
                token ?
                  <li className="nav-item">
                    <NavLink className="nav-link" to='/profile'>
                      <i className="nc-icon nc-badge"></i>

                    </NavLink>
                  </li>
                  :
                  <li className="nav-item">
                    <a className="nav-link" href='https://auth.eu-ep1.doubleextortion.com/login?client_id=49g8542gda2lstovq74aii323t&response_type=token&scope=email+openid+phone+profile&redirect_uri=https://doubleextortion.com/'>
                      <i className="nc-icon nc-single-02"></i>

                    </a>
                  </li>
              )}

              {(
                token ?
                  <li className="nav-item">
                    <NavLink className="nav-link" to='/query'>
                      <i className="nc-icon nc-zoom-split"></i>

                    </NavLink>
                  </li>
                  :
                  <span />
              )}
              {(
                token ?
                  <li className="nav-item">
                    <NavLink className="nav-link" to='/ask'>
                      <i className="nc-icon nc-audio-92"></i>

                    </NavLink>
                  </li>
                  :
                  <span />
              )}
              {(
                token ?
                  <li className="nav-item">
                    <NavLink className="nav-link" to='/report'>
                      <i className="nc-icon nc-single-copy-04"></i>

                    </NavLink>
                  </li>
                  :
                  <span />
              )}
              {(
                token ?
                  <li className="nav-item">
                     <div className="nav-link" onClick={this.handleLogoutClick }  to='/land' >
                      <i style={{color:'#c9b1b1'}}className="nc-icon nc-button-power"></i>
                    </div>
                  </li>
                  :
                  <span />
              )}
                            <li className="nav-item">
                <a className="nav-link" href='https://blog.doubleextortion.com/'>
                  <i className="nc-icon nc-bulb-63"></i>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href='https://us1.list-manage.com/contact-form?u=c51dfebb743a50385261dba65&form_id=a53ccc372eb7f62abd40402bae4b47c1'>
                  <i className="nc-icon nc-chat-round"></i>

                </a>
              </li>
            </ul>

          </div>
        </div>

      </nav>
    )
  }
}

export default Navbar
