import React, { Component } from 'react'
import MuiAlert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar'

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';

import Plot from 'react-plotly.js';
import { CSVLink, CSVDownload } from "react-csv";


class PrivateDashboard extends Component {

  constructor(props) {
    super(props)

    if (!window.localStorage.getItem('dash_ts') || isNaN(new Date(window.localStorage.getItem('dash_ts'))) ){
      window.localStorage.setItem('dash_ts',  new Date(new Date().setMonth(new Date().getMonth()-1)).toISOString() )
    }
    if (!window.localStorage.getItem('dash_te') || isNaN(new Date(window.localStorage.getItem('dash_te'))) ){
      window.localStorage.setItem('dash_te',  new Date().toISOString()  )
    }

    this.state = {
      ts:     window.localStorage.getItem('dash_ts'),
      te:     window.localStorage.getItem('dash_te') ,
      topN:    window.localStorage.getItem('dash_topn') ,

      industries:    window.localStorage.getItem('dash_industries') ,
      countries:    window.localStorage.getItem('dash_countries') ,
      actors:    window.localStorage.getItem('dash_actors') ,
    
      dataset:        window.localStorage.getItem('dash_dataset') ?? 'ext',
      type:        window.localStorage.getItem('dash_type') ?? 'events',
      
      errorOpen:false,
      errorMessage:'',

      results: [],
      waitingResults: false
    }
  }

  componentDidMount = () => {
  }
  compoentDidUnMount = () => {
  }

  calculateDateDifference=(dateArray)=> {
    if (dateArray.length === 0) {
      return "N/A";
    }
    const dates = dateArray.map(item => new Date(item.date));
    dates.sort((a, b) => a - b);
    const timeDifference = dates[dates.length - 1] - dates[0];
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return `${daysDifference} days`;
  }


  setTopN = (value) => {
    window.localStorage.setItem('dash_topn', value);
    this.setState({ topN: value });
  }
  setTs = (value) => {
    window.localStorage.setItem('dash_ts', value.toISOString());
    this.setState({ ts: value.toISOString() });
  }
  setTe = (value) => {
    window.localStorage.setItem('dash_te', value.toISOString());
    this.setState({ te: value.toISOString() });
  }
  setIndustries = (value) => {
    window.localStorage.setItem('dash_industries', value);
    this.setState({ industries: value });
  }
  setCountries = (value) => {
    window.localStorage.setItem('dash_countries', value);
    this.setState({ countries: value });
  }
  setActors = (value) => {
    window.localStorage.setItem('dash_actors', value);
    this.setState({ actors: value });
  }
  setDataset = (dataset) => {
    window.localStorage.setItem('dash_dataset', dataset);
    this.setState({ dataset: dataset });
  }
  setType = (type) => {
    window.localStorage.setItem('dash_type', type);
    this.setState({ type: type });
  }


  handleSearch = async () => {

    this.setState({
      waitingResults: true,
      errorOpen:false,
      errorMessage:'',
    });

    const apiEndpoint = window.localStorage.getItem('apiEndpoint') ?? "https://api.eu-ep1.doubleextortion.com/v1";
    const apiKey = window.localStorage.getItem('apiKey');
    const token = window.sessionStorage.getItem('token');

    const body = {};
    const headers = {};

    headers['Authorization'] = token;
    headers['Content-Type'] = 'application/json';
    headers['X-Api-Key'] = apiKey;

    try {

      const url = new URL(`${apiEndpoint}/dbtr/stats`);
      const params = new URLSearchParams();
      params.append('ts', new Date(this.state.ts).toISOString().split('T')[0] );
      params.append('te', new Date(this.state.te).toISOString().split('T')[0] );
      params.append('dset', this.state.dataset );
      params.append('stype', this.state.type );
      params.append('top', this.state.topN );
      if (this.state.industries && this.state.industries!=''){ params.append('industryFilter', this.state.industries ); }
      if (this.state.countries && this.state.countries!='')  { params.append('countryFilter', this.state.countries );}
      if (this.state.actors && this.state.actors!='')        { params.append('actorFilter', this.state.actors );}

      url.search = params.toString();
      const finalURL = url.toString();

      console.log(`[-] querying to "${`${apiEndpoint}/dbtr/stats`}":`, params.toString())
      fetch(finalURL, {
        method: 'GET',
        headers: { ...headers },
        mode: 'cors'
      })
        .then(async (response) => {
          console.log(`[+] results retrieved:`, response)
          const data = await response.json();
          console.debug(`[+] data retrieved:`, data)
          this.setState({
            waitingResults: false,
            results: data
          });

        })
        .catch(async (err) => {
          console.log(`[!] results error:`, err)
          this.setState({
            waitingResults: false,
            results: { Error: err?.message },
            errorOpen:true,
            errorMessage:`Error while retrieving data: ${err.message}`
          });

        });

    } catch (err) {
      console.error(`[!] Something wrong while retrieving data:`, err);
      this.setState({
        waitingResults: false,
        errorOpen:true,
        errorMessage:`Error while retrieving data: ${err.message}`
      });

    }
  };

  render() {

    const industries = this.state.industries;
    const countries = this.state.countries;
    const actors = this.state.actors;

    const topN = this.state.topN;

    const ts = this.state.ts;
    const te = this.state.te;
    
    const dataset = this.state.dataset;
    const stype = this.state.type;

    let type = null;
    
    let results=[];

    let tabularData=[]
    let pieData={labels:[], series:[]}

    let top=0
    let count=0
    let name='N/A'


    if (this.state.results ){
      const names =["revenue","employees","country","sector","actor","event"];
      let stats=null;
      for (let i=0;i<names.length;i++){ if (this.state.results[names[i]] ){ type=names[i]; stats=this.state.results[names[i]]; break;} }
      if (stats){
        count = stats.count;
        top = stats.top;
        name=stats.name;
        pieData={
          labels: stats.nums.map(x=>x.key), 
          series: stats.nums.map(x=>x.count)
        }
        tabularData = stats.nums;
      } 
    }

    let enabled_gourps=[]
    try{
      const access_token_p = JSON.parse(window.atob(window.sessionStorage.getItem('access_token').split('.')[1], 'base64').toString());
      enabled_gourps = access_token_p["cognito:groups"]  
    } catch (ex){
   
    }

    return (
      <div className="content">
                <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={this.state.waitingResults}
          onClose={() => { }}
          message='Retrieving Results...'
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={this.state.errorOpen}
          onClose={() => { }} >
           <MuiAlert elevation={6} variant="filled" severity="error">{this.state.errorMessage}</MuiAlert>
        </Snackbar>
        <div className="container-fluid">
        <div className="row">
              <div>
                <br />
                <h3 className="card-subtitle h3 text-white">Statistics Dashboard</h3>
              </div>
          </div>
          <div className="row">
              <div className="card-body text-white text-justify">
              The Statistics Dashboard is the user interface of the DEP Platform's <b>Stats API</b>. The Stats API enables you to extract aggregated information about events tracked by the platform, including details about actors, involved organizations, impacted business sectors, turnover of affected organizations, and estimated employee numbers.
              </div>
          </div>


          <div className="row">
            <div>
              <br />
              <h3 className="card-subtitle h6 text-white">Dashboard Parameters</h3>
            </div>
          </div>
          <hr style={{ backgroundColor: '#a05b5b', width: '100%' }} ></hr>
          <div className="row">

            <div className="col-sm">
              <div className="message text-white">

                <div className='row align-items-center'>
                  <div className='col-sm'>
                    <TextField
                      style={{
                        backgroundColor: 'white', border: '2px solid #a05b5b', width: '100%',
                        '&:hover': { border: '5px solid red' },
                      }}
                      id="ts"
                      label="Time Start"
                      type="date"
                      format={'YYYY/MM/DD'}  
                      defaultValue={new Date(ts).toISOString().split('T')[0]}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => this.setTs(e.target.valueAsDate)}
                    />
                  </div>
                  
                  <div className='col-sm'>
                    <TextField
                      style={{
                        backgroundColor: 'white', border: '2px solid #a05b5b', width: '100%',
                        '&:hover': { border: '5px solid red' },
                      }}
                      id="te"
                      label="Time End"
                      type="date"
                      format={'YYYY/MM/DD'}
                      defaultValue={new Date(te).toISOString().split('T')[0]}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => this.setTe(e.target.valueAsDate)}
                    />
                  </div>
                </div>
                <br/>
                <div className='row align-items-center'>
                  <div className='col-sm'>Dataset:</div>
                  <div className='col-sm'>

                    <Select
                      style={{ backgroundColor: 'white', width: '100%', textAlign: 'center' }}
                      labelId="dSet"
                      id="qSet-selector"
                      value={dataset}
                      onChange={(e) => this.setDataset(e.target.value)} >
                       {( enabled_gourps.indexOf('extortions-users')!=-1  || enabled_gourps.indexOf('full-access')!=-1?<MenuItem value='ext'>Extortions</MenuItem>:<div></div>)}
                       {( enabled_gourps.indexOf('opennews-users')!=-1  || enabled_gourps.indexOf('full-access')!=-1?<MenuItem value='nws'>OpenNews</MenuItem>:<div></div>)}
                       {( enabled_gourps.indexOf('privacy-users')!=-1  || enabled_gourps.indexOf('full-access')!=-1?<MenuItem value='prv'>Privacy</MenuItem>:<div></div>)}
                       {( enabled_gourps.indexOf('vandalism-users')!=-1  || enabled_gourps.indexOf('full-access')!=-1?<MenuItem value='vnd'>Vandalism</MenuItem>:<div></div>)}
                       {( enabled_gourps.indexOf('forum-users')!=-1  || enabled_gourps.indexOf('full-access')!=-1?<MenuItem value='frm'>Underground</MenuItem>:<div></div>)}
                       {( enabled_gourps.indexOf('ddos-users')!=-1  || enabled_gourps.indexOf('full-access')!=-1?<MenuItem value='dds'>DDoS</MenuItem>:<div></div>)}
                    </Select>

                  </div>
                </div>
                <br/>
                <div className='row align-items-center'>
                  <div className='col-sm'>Stats type:</div>
                  <div className='col-sm'>

                    <Select
                      style={{ backgroundColor: 'white', width: '100%', textAlign: 'center' }}
                      labelId="dSet"
                      id="tSet-selector"
                      value={stype}
                      onChange={(e) => this.setType(e.target.value)} >
                      <MenuItem value='en'>Event Timeline</MenuItem>
                      <MenuItem value='an'>Actors</MenuItem>
                      <MenuItem value='vr'>Revenue Ranges</MenuItem>
                      <MenuItem value='vc'>Targeted Countries</MenuItem>
                      <MenuItem value='vs'>Business Verticals</MenuItem>
                      <MenuItem value='ve'>Employees Ranges</MenuItem>
                    </Select>

                  </div>
                </div>
                <br/>
                <div className='row align-items-center'>
                  <div className='col-sm'>Top</div>
                  <div className='col-sm'>
                    <Slider
                      value={topN}
                      aria-labelledby="discrete-slider"
                      valueLabelDisplay="auto"
                      step={1}
                      
                      min={5}
                      max={50}
                      style={{ color: '#a05b5b' }}
                      onChange={(e, value) => this.setTopN(value)}
                    />
                  </div>
                </div>


              </div>
            </div>
            <div className="col-sm">
              <div className="message text-white">

                  <div className="row align-items-center">
                      <div className="col-2">
                      <Tooltip title="Comma separated list of Industries"><span className="text-white"> Industry Filter:</span></Tooltip><br />
                      </div>
                      <div className="col">
                        <TextField style={{
                          backgroundColor: 'white', border: '2px solid #a05b5b', width: '100%',
                          '&:hover': { border: '5px solid red' },
                        }} id="keyword-text" label="" variant="outlined" value={industries}  onKeyDown={this.keyPress} onChange={(e) => this.setIndustries(e.target.value)} />
                      </div>
                  </div>
                  <div className="row align-items-center">
                      <div className="col-2">
                      <Tooltip title="Comma separated list of Industries"><span className="text-white">Country Filter:</span></Tooltip><br />
                      </div>
                      <div className="col">
                        <TextField style={{
                          backgroundColor: 'white', border: '2px solid #a05b5b', width: '100%',
                          '&:hover': { border: '5px solid red' },
                        }} id="keyword-text" label="" variant="outlined" value={countries} onKeyDown={this.keyPress} onChange={(e) => this.setCountries(e.target.value)} />
                      </div>
                  </div>
                  <div className="row align-items-center">
                      <div className="col-2">
                      <Tooltip title="Comma separated list of Actors"><span className="text-white">Actor Filter:</span></Tooltip><br />
                      </div>
                      <div className="col">
                        <TextField style={{
                          backgroundColor: 'white', border: '2px solid #a05b5b', width: '100%',
                          '&:hover': { border: '5px solid red' },
                        }} id="keyword-text" label="" variant="outlined" value={actors} onKeyDown={this.keyPress} onChange={(e) => this.setActors(e.target.value)} />
                      </div>
                  </div>
              
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col ">
              <br />
              <button disabled={this.state.waitingResults} style={{ width: '100%' }} className="button-53 text-uppercase" onClick={this.handleSearch}>Search</button>
            </div>
          </div>


          <br />

          <div className="row">
            <div className="card w-100">
            <div className="card-header">
              <div className="col h6 text-center">

                <CSVLink className="link-dark text-dark" filename={`dep_stats-${dataset}-${type}_${new Date(ts).toISOString().split('T')[0]}-${new Date(te).toISOString().split('T')[0]}.csv`} data={tabularData}><b>Export Statistics </b> <i className="nc-icon nc-cloud-download-93 text-dark"></i></CSVLink>

              </div>
              <div className="col">
              </div>
            </div>
            </div>
           </div>
                      
          <div className='row'>

            <div className='col-sm'>

              <div className="card">
                <div className="card-header ">
                  <h4 className="card-title">Most frequent {`${type??'N/A'}`} values between {`${new Date(ts).toISOString().split('T')[0]}`} and {`${new Date(te).toISOString().split('T')[0]}`}</h4>
                  <p className="card-category">{`${type??'N/A'}`.toUpperCase()}</p>
                </div>
                <div className="card-body">
                  <Plot
                    className="w-100 h-50"
                    data={[
                      {
                        name: `${type??'N/A'}`,
                        x: pieData.labels,
                        y: pieData.series,
                        type: 'bar',
                        colorscale: 'Prism',
                        color: 'Prism',
                        pull: 0.05,
                        automargin: true,
                        marker: {
                          line: { width: 0.5 },
                          colors:
                            ["#fff7ec", "#fff7ec", "#fff7ec", "#fee8c8", "#fdd49e", "#fdbb84", "#fc8d59", "#ef6548", "#d7301f", "#b30000", "#7f0000"].reverse()
                              .map(hex => {
                                var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
                                return result ? `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)})` : '#000';
                              })
                        }
                      },
                    ]}
                    layout={{
                      autosize: true, font: { size: 10 }, showlegend: true,
                      legend: { xanchor: 'center', x: 0.5, orientation: 'h' }
                    }}
                    config={{ responsive: false, displayModeBar: false, modeBarButtonsToRemove: ['pan2d', 'select2d', 'lasso2d', 'resetScale2d', 'zoomOut2d'] }}
                  />
                  <hr />
                  <small className="text-center">The {`${name??'N/A'}`} plot shows the distribution of events among the top {`${top}`} most recurrent values (total values: {`${count}`}). </small>
                </div>
              </div>


            </div>
                            
          </div>

         
        </div>
      </div>
    )
  }
}

export default PrivateDashboard
