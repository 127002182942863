import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import './assets/css/bootstrap.min.css'
import './assets/css/light-bootstrap-dashboard.css'
//import './assets/css/dashboard.css'

import './index.css'

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
