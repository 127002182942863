import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { DataGrid } from '@material-ui/data-grid';

import { CSVLink, CSVDownload } from "react-csv";


const columns = [{
  dataField: 'date',
  text: 'Date'
}, {
  dataField: 'victim',
  text: 'Victim'
}, {
  dataField: 'sector',
  text: 'Sector'
}, {
  dataField: 'actor',
  text: 'Actor'
}, {
  dataField: 'country',
  text: 'Country'
}
];

class DataTable extends Component {

  constructor(props) {
    super(props)
    this.state = {}
    this.loadRansomwareEvents()
  }

  compoentDidMount() {

  }

  loadRansomwareEvents = () => {
    console.info('loading events in datatable')
    var events = JSON.parse(window.sessionStorage.getItem('events') || "[]")
    console.info("retrieved " + events.length + " events ")
    this.state.revents = events
  }

  calculateDateDifference=(dateArray)=> {
    if (dateArray.length === 0) {
      return "N/A";
    }
    const dates = dateArray.map(item => new Date(item.date));
    dates.sort((a, b) => a - b);
    const timeDifference = dates[dates.length - 1] - dates[0];
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return `${daysDifference} days`;
  }

  render() {
    var data = this.state.revents
    data.forEach((d, i) => {
      d.idx = i
      d.date = d.date.split('T')[0]

    });
    /*  */
    return (
      <div className="content">
        <div className="container-grid">

          <div className="row">
            <div className="col-8">
              <h2 className="card-body text-white "><b>Presenting the DoubleExtortion Cyber Extortion Lightweight Dataset</b></h2>
              <div className="card-body text-white">
                Your Insight into Extortion Events Over the Years. This dataset represents a <b>compact version</b> of our comprehensive cyber extortion dataset (last {`${this.state.revents.length}`} events, limited details), readily accessible through the platform. It's our contribution to the community for <b>non-commercial</b> use under the <a href="https://creativecommons.org/licenses/by-nc-sa/4.0">CC BT-CN-SA 4.0</a> license.
              </div>

              <h3 className="card-body text-white "><b>Unveiling Cyber Extortion: The Dual-Edged Cyberattack. </b></h3>
              <div className="card-body text-white">


                Cyber extortion, often termed as double extortion attacks, represents a formidable cyber threat landscape. In this breed of cyberattack, malevolent actors infiltrate a victim's network, pilfer sensitive data, and wield a two-pronged threat – either releasing it to the public or encrypting it, all while demanding a ransom for safe retrieval.


              </div>
              <h3 className="card-body text-white "><b>Is There More?</b></h3>
              <div className="card-body text-white">
                Absolutely, beyond the <b>Comprehensive</b> Extortion dataset, we offer an array of <b>additional</b> Cyber Intelligence datasets tailored for business decision makers.

                <br />

              </div>

            </div>
            <div className="col-4">
              <div className="text-center">
              <img className="h-25 rounded" style={{ marginLeft: '20px', width: '470px', height: 'auto' }} src="man.png" alt="" />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              
            </div>
          </div>

          <div className="row">
            <div className="col">
              

              <div className="col">
                <div className='d-flex justify-content-center'>
                  <a href="https://auth.eu-ep1.doubleextortion.com/login?client_id=49g8542gda2lstovq74aii323t&response_type=token&scope=email+openid+phone+profile&redirect_uri=https://app.doubleextortion.com/" target='blank'>
                    <button className="button-53 text-uppercase"><b>LOGIN TO THE PLATFORM</b></button>
                  </a>
                </div>
              </div>

            </div>
          </div>
          <br /><br />
          <div className="row">
            <div className="col">
              <div className="card w-100">
                <div className="card-header">


                  <div className="row">
                    <div className="col h6 text-right">
                      Cyber Extortion lightweight Data Table (Last {`${this.calculateDateDifference(this.state.revents)}`})
                    </div>
                    <div className="col">
                      <CSVLink className="link-dark" filename={`doubleextortion_export_${new Date().toISOString()}.csv`} data={data.map(e => { delete e.breached; delete e.disclosed; delete e.encrypted; delete e.idx; return e; })}><i className="nc-icon nc-cloud-download-93"></i></CSVLink>
                    </div>

                  </div>

                </div>
                <div className="card-body justify-content-center">



                  <div style={{ height: '700px' }} className="w-100 min-vh-100">
                    <DataGrid
                      rows={data.map((e, i) => { e.id = i + 1; return e; })}
                      columns={[{
                        field: 'date',
                        headerName: 'Date',
                        width: 150,
                        editable: false,
                      }, {
                        field: 'victim',
                        headerName: 'Victim',
                        width: 250,
                        editable: false,
                      }, {
                        field: 'sector',
                        headerName: 'Sector',
                        width: 200,
                        editable: false,
                      },
                      {
                        field: 'actor',
                        headerName: 'Actor',
                        width: 150,
                        editable: false,
                      },
                      {
                        field: 'country',
                        headerName: 'Country',
                        width: 200,
                        editable: false,
                      }]}

                      pageSize={50}
                      rowsPerPageOptions={[50]}

                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <h3 className="card-body text-white"><b>I need to use these data on my research project, how should I do it?</b></h3>
              <div className="card-body text-white">
                Here at Double Extortion we deeply support academic and independent research. So, if you are a researcher and you want to use the free version of the cyber extortion dataset for your research or publication, feel free to use it: the data is available under the <a rel="license" href="https://creativecommons.org/licenses/by-nc-sa/4.0">CC BY-NC-SA 4.0</a> license. To comply with the license you could use a BibTeX reference like the following:
                <br /><br />
                <pre><code className='card'>{`
@misc{ LM,
       author = "Luca Mella, M.Eng.",
       title = "Double Extortion: Cyber Extortion Attack & Breach Tracker",
       year = "${new Date().getFullYear()}",
       url = "https://doubleextortion.com/",
       note = "[Online; ${new Date().toDateString()}]"
     }
`}
                </code></pre>
                <br />
              </div>

              <div className="card-body text-white text-center">
                This is a <b>light version</b> of the cyber extortion dataset available through the platform and is freely provided for <b>non-commercial</b> purposes under <a href="https://creativecommons.org/licenses/by-nc-sa/4.0">CC BT-CN-SA 4.0</a>.
              </div>

              <div className='d-flex justify-content-center'>



                <a href="https://auth.eu-ep1.doubleextortion.com/login?client_id=49g8542gda2lstovq74aii323t&response_type=token&scope=email+openid+phone+profile&redirect_uri=https://app.doubleextortion.com/" target='blank'>
                  <button className="button-53 text-uppercase"><b>LOGIN TO THE PLATFORM</b></button>
                </a>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DataTable
