import React, { Component, useRef } from 'react'
import MuiAlert from '@material-ui/lab/Alert';
import Select from '@material-ui/core/Select';
import Slider from '@material-ui/core/Slider';
import MenuItem from '@material-ui/core/MenuItem';

import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar'
import TextField from '@material-ui/core/TextField';
import ReactJson from 'react-json-view'
import { CSVLink, CSVDownload } from "react-csv";
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';


function checkIsValidDomain(domain) {
  var re = new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/i);
  return domain.match(re) ? true : false;
}


const FileUploader = ({ handleFile }) => {
  const hiddenFileInput = useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    handleFile(fileUploaded);
  };
  return (
    <i style={{ color: '#787171' }} onClick={handleClick} className="text-white nc-icon nc-cloud-upload-94">
      <input
        type="file"
        onChange={handleChange}
        ref={hiddenFileInput}
        style={{ display: "none" }} // Make the file input element invisible
      />
    </i>
  );
};
const downloadFile = ({ data, fileName, fileType }) => {
  // Create a blob with the data we want to download as a file
  const blob = new Blob([data], { type: fileType })
  // Create an anchor element and dispatch a click event on it
  // to trigger a download
  const a = document.createElement('a')
  a.download = fileName
  a.href = window.URL.createObjectURL(blob)
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  })
  a.dispatchEvent(clickEvt)
  a.remove()
}

function LinearProgressWithLabel(props) {
  return (
    <Box style={{ display: props.display }} display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress color='primary' variant="determinate"  {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" className='text-white'>{`${Math.round(props.value,)}%`}</Typography>
      </Box>
    </Box>
  );
}


function csvFields(results) {
  if (!results || !Array.isArray(results)){ return []}

  return results.map(result=>{
    if (!result){return;}
    try{
      const el={
        orgName : result.orgName??'',
        date  : result.date,
        victim  : result.victim,
        victimSector  : result.victimSector,
        victimCountry  : result.victimCountry,
        victimRevenue      :  ( Object.keys(result?.victimDetails).length>0? result?.victimDetails?.metrics?.estimatedAnnualRevenue : ''),
        victimEmployees    :  ( Object.keys(result?.victimDetails).length>0? result?.victimDetails?.metrics?.employeesRange : ''),
        victimLegalName    :  ( Object.keys(result?.victimDetails).length>0? result?.victimDetails?.legalName : ''),
        victimLink   : result.victimLink,
        actor        : result.actor,
        annTitle     : result.annTitle,
        annLink      : result.annLink,
        annDataTypes      : (Array.isArray(result.annDataTypes)?result.annDataTypes:[]).join(','),
        annDescription      : result.annDescription,
        confidence: result.confidence
      };
      return el;  
    } catch (err){
      console.log(`[!] error exporting CSV data (${err}):`,result);
      return;
    }
  }).filter(x=>x);
}

class QueryBatch extends Component {

  constructor(props) {
    super(props)
    this.state = {
      reportQuery: JSON.parse(window.localStorage.getItem('qbatch_query')) ?? {},
      queryData: JSON.parse(window.localStorage.getItem('qbatch_queryDataRaw')) ?? [],
      dataset: window.localStorage.getItem('qbatch_dataset') ?? 'ext',
      maxResults: parseInt(window.localStorage.getItem('qbatch_maxResults') ?? 1),
      totQueries: 0,
      doneQueries: 0,
      errQueries: 0,
      results: [],
      errorOpen: false,
      errorMessage: ``,
      waitingResults: false
    }
  }

  componentDidMount() {

  }



  setDataset = (dataset) => {
    window.localStorage.setItem('qbatch_dataset', dataset);

    this.setState({ dataset: dataset });
  }
  setMaxResults = (value) => {
    window.localStorage.setItem('qbatch_maxResults', value);

    this.setState({ maxResults: value });
  }
  setReportQuery = (queryData) => {
    const reportQuery = [];
    queryData.forEach(r => {
      const record = {}
      record.name = r.name.trim();
      record.keyw = r.keyw.split(',').map(s => s.trim());
      record.keyw = record.keyw.filter(s => s && s.length > 0).join(',');
      record.domains = r.domains.split(',').map(s => s.trim());
      record.domains = record.domains.filter(s => /^(?:(?:[a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,})$/.test(s)).join(',');
      reportQuery.push(record);
    })
    window.localStorage.setItem('qbatch_query', JSON.stringify(reportQuery));
    this.setState({ reportQuery: reportQuery });
  }

  addNewOrg = () => {
    const queryData = this.state.queryData;
    queryData.push({ name: '', keyw: '', domains: '' })
    this.setState({ queryData: queryData })
  }
  handleInputChange = (index, field, value) => {
    const data = this.state.queryData;
    const newData = [...data];
    newData[index][field] = value;
    this.setReportQuery(newData);
    window.localStorage.setItem('qbatch_queryDataRaw', JSON.stringify(newData))
    this.setState({ queryData: newData });
  }
  keyPress = (e) => {
    if (e.keyCode == 13) {
      console.log('value', e.target.value);
      if (!this.state.waitingResults) {
        return this.handleSubmit();
      }
    }
  }


  handleFile = async (fileUploaded) => {
    if (!fileUploaded) { return }
    const data = await fileUploaded.text();
    const jdata = JSON.parse(data);
    this.setReportQuery(jdata);
    window.localStorage.setItem('qbatch_queryDataRaw', JSON.stringify(jdata))
    this.setState({ queryData: jdata });
    return;
  }

  clearReportQuery = async () => {
    this.setReportQuery([]);
    window.localStorage.setItem('qbatch_queryDataRaw', JSON.stringify([]))
    this.setState({ queryData: [] });
  }

  handleSubmit = async () => {

    this.setState({
      waitingResults: true,
      errorOpen: false,
      errorMessage: ``,
      results: [],
      totQueries: 0,
      doneQueries: 0,
      errQueries: 0,
    });

    const apiEndpoint = window.localStorage.getItem('apiEndpoint') ?? "https://api.eu-ep1.doubleextortion.com/v1";
    const apiKey = window.localStorage.getItem('apiKey');
    const token = window.sessionStorage.getItem('token');
    const body = {};
    const headers = {};

    headers['Authorization'] = token;
    headers['Content-Type'] = 'application/json';
    headers['X-Api-Key'] = apiKey;

    try {

      body.customer = this.state.reportCustomer;
      body.name = this.state.reportName;
      body.emails = this.state.recipients;
      body.orgs = this.state.reportQuery;

      let totQueries = 0;
      let doneQueries = 0;
      let errQueries = 0;
      const searchQueries = {};

      for (let i = 0; i < this.state.reportQuery.length; i++) {
        const rq = this.state.reportQuery[i];
        const name = rq.name.trim();
        const keyws = rq.keyw.split(',').map(s => s.trim()).filter(z => z && z != '');
        const domains = rq.domains.split(',').map(s => s.trim()).filter(z => z && z != '');
        const sq = keyws.map(k => { return { keyw: k, extended: false, dset: this.state.dataset, maxres: this.state.maxResults } });
        if (domains.length > 0) {
          sq.push({ domains: domains, extended: false, dset: this.state.dataset, maxres: this.state.maxResults })
        }
        searchQueries[name] = sq;
        totQueries += sq.length;
      }
      for (let name in searchQueries) {

        console.log(`[+] processing org "${name}" (${searchQueries[name].length} searches)`)
        for (let i = 0; i < searchQueries[name].length; i++) {
          try {
            let response = await fetch(`${apiEndpoint}/dbtr/search`, {
              method: 'POST',
              headers: { ...headers },
              body: JSON.stringify(searchQueries[name][i]),
              mode: 'cors'
            });
            console.log(`[+] results retrieved for ${name}_${i}`)
            const data = await response.json();
            //console.debug(`[+] data retrieved:`, data)
            const results = this.state.results ?? [];
            const results_new = results.concat((Array.isArray(data) ? data.map(d => { d.orgName = name; return d; }) : []))
            this.setState({
              results: results_new,
            });
          } catch (err) {
            console.log(`[!] Query error (${err}):`, searchQueries[name][i]);
            errQueries++;
            this.setState({
              errorOpen: true,
              errorMessage: `Error while retrieving data for ${name}_${i}: ${err.message}`,
            });
          } finally {
            doneQueries++;
            this.setState({
              totQueries,
              doneQueries,
              errQueries,
              waitingResults: true,
            });
          }
        }
      };
      this.setState({
        waitingResults: false,
      });
    } catch (err) {
      console.error(`[!] Something wrong while retrieving data:`, err);
      this.setState({
        waitingResults: false,
        errorOpen: true,
        errorMessage: `Error while retrieving data: ${err.message}`
      });
    }
  };

  exportToJson = async () => {

    downloadFile({
      data: JSON.stringify(this.state.queryData),
      fileName: `query-batch_config-${new Date().toISOString()}.json`,
      fileType: 'text/json',
    })
  }

  render() {
    const token = window.sessionStorage.getItem('token') || null
    const apiKey = window.sessionStorage.getItem('apikey') || null
    const reportCustomer = this.state.reportCustomer;
    const reportName = this.state.reportName;
    const reportRecipients = this.state.recipients;
    const recipientsText = this.state.recipientsText;
    const reportQuery = this.state.reportQuery;
    const queryData = this.state.queryData ?? [];
    const dataset = this.state.dataset;
    const results = this.state.results ?? [];
    const maxResults = this.state.maxResults;

    let enabled_gourps = []
    try {
      const access_token_p = JSON.parse(window.atob(window.sessionStorage.getItem('access_token').split('.')[1], 'base64').toString());
      enabled_gourps = access_token_p["cognito:groups"]
    } catch (ex) {

    }


    return (
      <div className="content">

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={this.state.waitingResults}
          onClose={() => { }}
          message='Retrieving Results...'
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={this.state.errorOpen}
          onClose={() => { }} >
          <MuiAlert elevation={6} variant="filled" severity="error">{this.state.errorMessage}</MuiAlert>
        </Snackbar>
        <div className="container-fluid" >

          <div className="row">
            <div>
              <br />
              <h3 className="card-subtitle h3 text-white">Company Query Batch</h3>
            </div>
            <div className="card-body text-white text-justify">
              The Company Query Batch page harnesses the power of the <b>Search API</b> to empower users with the ability to configure and execute queries on the Double Extortion Platform programmatically. Within the Company Query Batch page, you can create intricate query configurations to search for multiple organizations using various keywords and domain names. You can save your queries for future use and execute them to extract relevant data through the platform's Search API.
            </div>
          </div>

          <div className="row">
            <div>
              <br />
              <h3 className="card-subtitle h6 text-white">Query Batch Parameters</h3>
            </div>
          </div>
          <hr style={{ backgroundColor: '#a05b5b', width: '100%' }} ></hr>
          <div className="row">

            <div className="col-sm">
              <div className="message text-white">

                <div className='row align-items-center'>
                  <div className='col-sm'>Dataset:</div>
                  <div className='col-sm'>

                    <Select
                      style={{ backgroundColor: 'white', width: '100%', textAlign: 'center' }}
                      labelId="dSet"
                      id="qSet-selector"
                      value={dataset}
                      onChange={(e) => this.setDataset(e.target.value)} >
                      {(enabled_gourps.indexOf('extortions-users') != -1 || enabled_gourps.indexOf('full-access') != -1 ? <MenuItem value='ext'>Extortions</MenuItem> : <div></div>)}
                      {(enabled_gourps.indexOf('opennews-users') != -1 || enabled_gourps.indexOf('full-access') != -1 ? <MenuItem value='nws'>OpenNews</MenuItem> : <div></div>)}
                      {(enabled_gourps.indexOf('privacy-users') != -1 || enabled_gourps.indexOf('full-access') != -1 ? <MenuItem value='prv'>Privacy</MenuItem> : <div></div>)}
                      {(enabled_gourps.indexOf('vandalism-users') != -1 || enabled_gourps.indexOf('full-access') != -1 ? <MenuItem value='vnd'>Vandalism</MenuItem> : <div></div>)}
                      {( enabled_gourps.indexOf('forum-users')!=-1  || enabled_gourps.indexOf('full-access')!=-1?<MenuItem value='frm'>Underground</MenuItem>:<div></div>)}
                      {( enabled_gourps.indexOf('ddos-users')!=-1  || enabled_gourps.indexOf('full-access')!=-1?<MenuItem value='dds'>DDoS</MenuItem>:<div></div>)}
                    </Select>

                  </div>
                </div>

                <div className='row align-items-center'>
                  <div className='col-sm'>Max Results:</div>
                  <div className='col-sm'>
                    <Slider
                      value={maxResults}
                      aria-labelledby="discrete-slider"
                      valueLabelDisplay="auto"
                      step={1}

                      min={1}
                      max={10}
                      style={{ color: '#a05b5b' }}
                      onChange={(e, value) => this.setMaxResults(value)}
                    />
                  </div>
                </div>

              </div>
            </div>
            <div className="col-sm-6">
              <div className=" message text-white" >

                <div className="row align-items-center">
                  <button disabled={this.state.waitingResults} style={{ width: '100%' }} className="button-53 text-uppercase" onClick={this.addNewOrg}>Add Organization</button>
                  <br /> <br />
                </div>
                <div className="row align-items-center">
                  <div className='col align-items-center text-center'>
                    <Tooltip title='Load configuration from file'><div><small>Load </small><FileUploader handleFile={this.handleFile} /></div></Tooltip>
                  </div>
                  <div className='col align-items-center text-center'>
                    <Tooltip title='Export configuration to file'><div><small>Export </small><i style={{ color: '#787171' }} onClick={this.exportToJson} className="text-white nc-icon nc-cloud-download-93"></i></div></Tooltip>
                  </div>
                  <div className='col align-items-center text-center'>
                    <Tooltip title='Clear current configuration'><div><small>Clear </small><i style={{ color: '#787171' }} onClick={this.clearReportQuery} className="text-white nc-icon nc-simple-remove"></i></div></Tooltip>
                  </div>
                </div>
                <br />



              </div>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col">

              <div className="overflow-auto" style={{ overflow: "scroll", overflowX: "hidden", maxHeight: "300px" }}>
                {queryData.map((item, index) => (
                  <div key={index} className="row align-items-center">
                    <div className="col-sm-2">
                      <Tooltip title='Configure the keywords and domains for your organizational perimeter (use "," as separator)'>
                        <span className="text-white text-align-right small">Org.{`${index + 1}`}</span>
                      </Tooltip>
                    </div>
                    <div className="col-9">
                      <TextField style={{ backgroundColor: 'white', border: '2px solid #a05b5b', width: '100%', '&:hover': { border: '5px solid red' }, }}
                        type="text"
                        placeholder="Name"
                        value={item.name}
                        onChange={e => this.handleInputChange(index, 'name', e.target.value)}
                      />
                      <TextField style={{ backgroundColor: 'white', border: '2px solid #a05b5b', width: '100%', '&:hover': { border: '5px solid red' }, }}
                        type="text"
                        placeholder="Keywords"
                        value={item.keyw}
                        onChange={e => this.handleInputChange(index, 'keyw', e.target.value)}
                      />
                      <TextField style={{ backgroundColor: 'white', border: '2px solid #a05b5b', width: '100%', '&:hover': { border: '5px solid red' }, }}
                        type="text"
                        placeholder="Domains"
                        value={item.domains}
                        onChange={e => this.handleInputChange(index, 'domains', e.target.value)}
                      />
                    </div>
                  </div>
                ))}
              </div>


            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col ">
              <br />
              <button disabled={this.state.waitingResults} style={{ width: '100%' }} className="button-53 text-uppercase" onClick={this.handleSubmit}>Run Query Batch</button>
            </div>
          </div>


          <br />

          <div className="row">
            <div>
              <br />
              <h3 className="card-subtitle h6 text-white">Results</h3>
            </div>
          </div>

          <LinearProgressWithLabel lineColor='#b47373' display={(this.state.waitingResults ? 'inline' : 'none')} value={(this.state.totQueries == 0 ? 0 : 100 * (this.state.doneQueries / this.state.totQueries))} className='text-white' />


          <hr style={{ backgroundColor: '#a05b5b', width: '100%' }} ></hr>
          <div className="row ">
            <small className='text-white'>Export Results in CSV </small> <CSVLink className="link-dark" filename={`dep_search-${dataset}-export_${new Date().toISOString()}.csv`}
              data={csvFields(results)}><i className="nc-icon nc-cloud-download-93"></i></CSVLink>
          </div>
          <br />
          <div className="row">
            <div className='col'>
              <div className='card'>

                <ReactJson name={false} iconStyle='circle' collapsed={2} displayDataTypes={false} src={results} />

              </div>
            </div>
          </div>


        </div>
      </div >
    )
  }
}

export default QueryBatch
