import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';


class Profile extends Component {

  constructor(props) {
    super(props)
    this.state = {
      apiKey: null,
      apiEndpoint: null,
    }
  }

  componentDidMount() {

  }

  setApiKey = (v) => {
    window.localStorage.setItem('apiKey', v);
    this.setState({ apiKey: v })
  }
  setApiEndpoint = (v) => {
    window.localStorage.setItem('apiEndpoint', v);
    this.setState({ apiEndpoint: v })
  }


  render() {
    let token = window.sessionStorage.getItem('token') || null
    let refresh_token = window.sessionStorage.getItem('refresh_token') || null;
    let id_token = window.sessionStorage.getItem('id_token') || null;

    let apiKey = this.state.apiKey ? this.state.apiKey : window.localStorage.getItem('apiKey');
    let apiEndpoint = this.state.apiEndpoint ? this.state.apiEndpoint : window.localStorage.getItem('apiEndpoint')?? "https://api.eu-ep1.doubleextortion.com/v1";
    let loginTime, expirationTime
    var access_token_p = {};
    let enabled_gourps = []
    const available_gourps= { 
      "extortions-users":"Access to the Extortion data set",
      "privacy-users":"Access to the privacy data set", 
      "forum-users":"Access to the deepweb forum data set", 
      "opennews-users":"Access to the public news and company press statement module", 
      "vandalism-users":"Access to the cyber vandalism data set",
      "ddos-users":"Access to the DDoS data set",
      "search-users":"Access to search APIs functionalities", 
      "stats-users":"Access to statistical APIs", 
      "plist-users":"Access to the private list API", 
      "ask-users":"Access to the IntelQuery AI module", 
      "report-users":"Access to the reporting services",
      "full-access":"Compete access to the platform APIs"
    };

    try {
      access_token_p = JSON.parse(window.atob(window.sessionStorage.getItem('access_token').split('.')[1], 'base64').toString());
      loginTime = new Date(access_token_p.auth_time * 1000).toLocaleString()
      expirationTime = new Date(access_token_p.exp * 1000).toLocaleString()
      enabled_gourps = access_token_p["cognito:groups"]
    } catch (ex) {
      console.log(`[!] something wrong parsing access token: ${window.sessionStorage.getItem('access_token')}`);
    }

    const expirationTimeString=  expirationTime ?? 'N/A';
    return (
      <div className="content">
        <div className="container-fluid" >
          <div className="row">
            <div>
              <br />
              <h3 className="card-subtitle h3 text-white">Profile Page</h3>
            </div>
            <div className="card-body text-white text-justify">
            The Profile page provides a summary of your current access privileges within the platform, offering a convenient way to acquire authentication tokens essential for scripting with the platform's API. Additionally, it serves as the central hub for configuring both the <b>Endpoint</b> and the <b>API key</b> you were issued during the initial account activation process. 
            This page ensures you have complete control over your platform access and API integration settings.
            </div>
          </div>
          <div className="row ">
            <div>
              <br />
              <h3 className="card-subtitle h6 text-white">Platform Access Parameters</h3>
            </div>
          </div>
          <hr style={{ backgroundColor: '#a05b5b', width: '100%' }} ></hr>
          <div className="row">

            <div className="col-sm">
              <div className="message text-white">
                <span className='font-weight-bold'>User name:</span> <span >{access_token_p.username ?? 'N/A'}</span><br />
                <span className='font-weight-bold'>Login time:</span> <span>{loginTime ?? 'N/A'}</span><br />
                <span className='font-weight-bold'>Expiration time:</span> <Tooltip title={new Date(expirationTimeString) < new Date()?'Token Expired! Logout and then login again.':''}>{
                  (new Date(expirationTimeString) > new Date() ? 
                  <span className="text-dark badge badge-pill badge-success">{new Date(expirationTimeString).toString()}</span> : 
                  <span className="text-dark badge badge-pill badge-warning">{new Date(expirationTimeString).toString()}</span> 
                  )
                }</Tooltip>
              </div>
            </div>
            <div className="col-sm">
              <div className="message text-white">
                <div className='row'>
                  Click here to copy your tokens in the clipboard:
                </div>
                <br />
                <div className='row justify-content-around'>

                  <div className='col-sm'>
                    <Tooltip title="Click to copy on clipboard!"><Button className="button-53 text-uppercase text-white" onClick={() => { navigator.clipboard.writeText(token) }}>Auth token</Button></Tooltip>
                  </div>
                  <div className='col-sm'>
                    <Tooltip title="Click to copy on clipboard!"><Button className="button-53 text-uppercase text-white" onClick={() => { navigator.clipboard.writeText(refresh_token) }}>Refresh token</Button></Tooltip>
                  </div>
                </div>



              </div>

            </div>

          </div>
          <br /><br /><br />
          <div className="row">
            <div>
              <br />
              <h3 className="card-subtitle h6 text-white">Modules Enabled</h3>
            </div>

          </div>
          <hr style={{ backgroundColor: '#a05b5b', width: '100%' }} ></hr>

          <div className="row">
            <div className='col-sm'>
              { Object.keys(available_gourps).map(g=>{
                if (enabled_gourps.indexOf(g)!=-1){
                  return <Tooltip  title={available_gourps[g]}><span className="text-white badge badge-pill badge-success">{g}</span></Tooltip>
                }
                else{
                  return <Tooltip  title={available_gourps[g]}><span className="text-white badge badge-pill badge-secondary">{g}</span></Tooltip>
                }
              })}
            </div>
            <div className='col-sm'>
              
            </div>

          </div>

          <br /><br /><br />
          <div className="row ">
            <div>
              <br />
              <h3 className="card-subtitle h6 text-white">Platform API Endpoint Configuration</h3>
            </div>
          </div>
          <hr style={{ backgroundColor: '#a05b5b', width: '100%' }} ></hr>

          <div className="row">

            <div className="col-sm">
              <div className="message text-white">
                <Tooltip title="Insert the API Endpoint you have been provided during account activation."><span>API Endpoint:</span></Tooltip> <span>
                  <TextField style={{
                    backgroundColor: 'white', border: '2px solid #a05b5b', width: '100%',
                    '&:hover': { border: '5px solid red' },
                  }} id="endpoint-text" label="" variant="outlined" value={apiEndpoint} onChange={(e) => this.setApiEndpoint(e.target.value)} />

                </span><br />
                <Tooltip title="Insert the API Key you have been provided during account activation."><span>API Key:</span></Tooltip> <span>
                  <TextField style={{
                    backgroundColor: 'white', border: '2px solid #a05b5b', width: '100%',
                    '&:hover': { border: '5px solid red' },
                  }} id="apikey-text" type="password" label="" variant="outlined" value={apiKey ?? ''} onChange={(e) => this.setApiKey(e.target.value)} />
                </span><br />
              </div>
            </div>
            <div className="col-sm">


            </div>


          </div>
          <div className='row'>
          </div>

        </div>
      </div>
    )
  }
}

export default Profile
