import React, { Component } from 'react'

class Search extends Component {

  constructor(props){
    super(props)
    
  }

  componentDidMount(){
    (function() {
      var cx, gcse, s;
      console.info('[-] dynamically adding google custom search script')

      // Deepweb Search
      cx = 'aa9fd6f71f129c4dc';
      gcse = document.createElement('script');
      gcse.type = 'text/javascript';
      gcse.async = true;
      gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
      s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(gcse, s);

      /*
      // Company  Search
      cx = '341efba757d798f4f';
      gcse = document.createElement('script');
      gcse.type = 'text/javascript';
      gcse.async = true;
      gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
      s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(gcse, s);


      // Ransomware Malware Search
      cx = '341efba860884711e2b64962757d798f4f';
      gcse = document.createElement('script');
      gcse.type = 'text/javascript';
      gcse.async = true;
      gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
      s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(gcse, s);
*/
    })();
  }

  render() {

   
    return (
      <div className="content">      
      <div className="container-fluid" >

         
        <div className="row">
          <div className="col">
            <div className="card w-100"> 
              <div className="card-header">
                
              </div>
              <div className="card-body">
              <div className="container-grid">
                <div className="row ">
                  <div className="col-xs-4 align-self-center text-center">
                    
                  </div>
                  <div className="col-sm-8">

                    <div className="message">
                    <h4 className="card-title">Deep Web Quick Search</h4><br/>
                    <h5 className="card-subtitle h6"></h5>
                    
                      <span className="card-text">With this TLP:CLEAR quick-search you can conduct preliminary searches on open information <b>publicly indexed</b>  by major search engines. </span>
                      <br/><br/>
                      <span className="card-text"><b>NOTE</b>: Additional data is hidden on those underground sites, but only accessible with non-public access. So, if you are concerned about the contents or need furthe information contact "in fo At doub leex tortion DoT com". </span>
                      <br/><br/>
                      <div className="card-text">
                      
                        <div className="gcse-searchbox" data-gname="DarkForums"> </div>
                        <div className="gcse-searchresults" data-gname="DarkForums"></div>

                      </div>

                    </div>

                  </div>
                </div>
              </div>
              
                
                
              </div>
            </div>
          </div>
        </div>
            
      
      
      </div>
      </div>
    )
  }
}

export default Search
