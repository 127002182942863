import React, { Component } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import { Switch, Route, Redirect } from 'react-router-dom'
import Dashboard from './Dashboard'
import DataTable from './DataTable'
import PrivateDataTable from './PrivateDataTable'
import PrivateDashboard from './PrivateDashboard'
import Search from './Search'
import About from './About'
import Profile from './Profile'
import Land from './Land'
import Query from './Query'
import QueryBatch from './QueryBatch'
import Ask from './Ask'
import Report from './Report'


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';


class Main extends Component {

  constructor(props){
    super(props)
    this.state={}
    this.state.intro_open=true;
  }

  render() {
    return (
      <div className="main-panel">

        
        


        <Navbar />
        <Switch>
          <Route path="/dashboard" render={(props) => ( <Dashboard loading={this.props.loading} /> )} />
          <Route path="/datatable" render={(props) => ( <DataTable loading={this.props.loading} /> )} />
          <Route path="/privatedashboard" render={(props) => ( <PrivateDashboard loading={this.props.loading} /> )} />
          <Route path="/privatedatatable" render={(props) => ( <PrivateDataTable loading={this.props.loading} /> )} />
          <Route path="/search" render={(props) => ( <Search loading={this.props.loading} /> )} />
          <Route path="/profile" render={(props) => ( <Profile loading={this.props.loading} /> )} />
          <Route path="/query" render={(props) => ( <Query loading={this.props.loading} /> )} />
          <Route path="/querybatch" render={(props) => ( <QueryBatch loading={this.props.loading} /> )} />
          <Route path="/ask" render={(props) => ( <Ask loading={this.props.loading} /> )} />
          <Route path="/report" render={(props) => ( <Report loading={this.props.loading} /> )} />


          <Redirect from='*' to='/dashboard' />
        </Switch>
        <Footer />
      </div>
    )
    //           <Route path="/land" render={(props) => ( <Land loading={this.props.loading} /> )} />
    //           <Route path="/about" render={(props) => ( <About loading={this.props.loading} /> )} />
  }
}

export default Main

