import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar';
import {  NavLink } from 'react-router-dom'


class Land extends Component {

  constructor(props){
    super(props)
    this.state={}
  }

  componentDidMount(){

  }



  render() {

    return (
    <div className="content" >      
      <div className="container-fluid" >
   
      <div className="row">
        <div className="col-8">
          <h1 className="text-white font-weight-bold " >We Track and Monitor the Cyber Space</h1>
          <h4 className="text-white font-weight-light text-justify"> 
          Introducing the <b>Double Extortion Platform (DEP)</b>: Your Exclusive Lens into the Cyber Realm.
<br/><br/>
In a world awash with data, Double Extortion Platform emerges as a key to unlocking a wealth of insights. With us, you gain the power to <b>pinpoint</b> organizations hit by major cyber assaults, acquiring <b>strategically pivotal intelligence</b> that's invaluable for businesses focused on vigilant risk management.<br/><br/>
At DEP, we orchestrate a symphony of data collection, processing, amplification, indexing, and the brilliance of AI algorithms across tens of thousands of data nodes. Our outcome? A masterpiece of insights, a Business Cyber Intelligence that's reshaping the game for discerning global enterprises.
          </h4>
          <div className='d-flex justify-content-center'>
            <NavLink className="nav-link" to='/datatable'>
            <button className="button-53 text-uppercase">EXPLORE OUR FREE SAMPLE SET</button>
            </NavLink>
          </div>
        </div>
        <div className="col-4">
          <div className="text-center">
          <img className="h-25 rounded" src="man.png" alt="" />
          </div>
        </div>
      </div>
            <br/>
            <br/>
      <div className="row ">
        <div className="col-6 ">
          <h2 className="text-white text-right align-middle align-text-bottom font-weight-bold"><b>Why leverage these data insights?</b></h2>
        </div>
        <div className="col-6">
          <h4 className="text-white font-weight-lighter text-justify">
            
          The cyber security track record exerts a substantial influence on a company's competitiveness, stability, and enduring value. Even well beyond the immediate aftermath of events, this impact remains pronounced. Decision makers who disregard these realities are at risk of <b>FINANCIAL LOSSES</b>.</h4>
        </div>
      </div>
      
            <br/>
            <br/>
      <div className="row ">
        <div className="col-12">
          <h1 className="text-white text-center font-weight-bold"><b>Discover the Use Cases</b></h1>
          <h4 className="text-white text-center font-weight-light text-justify">Discover how your business vertical can leverage the powerful insights from the Double Extortion Platform.</h4>
        </div>
        <div className="col-12">
        <br/>
                    <div className='d-flex justify-content-center'>
                    <a href="https://us1.list-manage.com/contact-form?u=c51dfebb743a50385261dba65&form_id=a53ccc372eb7f62abd40402bae4b47c1" target='blank'>
                    <button className="button-53 button"><b>ASK FOR OTHER DATASET AND PLATFORM ACCESS</b></button>
                    </a>
                   </div>
        </div>
      </div>
            <br/>
            <br/>
            <br/>
            <br/>
      <div className="row">
        <div className="col-4">
          <div className="text-right">
          <img className="h-25 w-50 rounded" src="insurance_white.png" alt="" />
          </div>
        </div>
        <div className="col-6">
          <h2 className="text-white font-weight-bold" ><b>Insurance & Finance</b></h2>
          <h4 className="text-white font-weight-lighter">
            
            The Double Extortion Platform plays a pivotal role in empowering insurance companies to navigate the intricate landscape of risk assessment. 
            Specifically tailored for entities deemed "at risk" due to their previous encounters with substantial threats, our platform provides invaluable insights. <br/>
            By delving into historical threat data, we assist insurance firms in comprehending the extent of risk associated with insuring these entities, enabling them to make informed decisions that balance profitability with security.
            Furthermore, our platform extends its prowess to the financial sector, providing robust data for evaluating risk exposure. This not only fortifies risk management strategies but also fosters a prudent approach to financial operations.

          </h4>
        </div>
      </div>
            <br/>
            <br/>
      <div className="row">

        <div className="col-6">
          <h2 className="text-white text-right font-weight-bolder" ><b>Large Industrial Groups</b></h2>
          <h4 className="text-white text-right font-weight-lighter ">
          The Double Extortion Platform serves as a valuable asset to expansive industrial conglomerates and multinational corporations boasting extensive and intricate <b>supply chains</b>. It addresses the necessity for operational insights that enable the vigilant monitoring of cybersecurity risks associated with their network of suppliers.

            </h4>
        </div>
        <div className="col-4">
          <div className="text-left">
          <img className="h-25 w-50 rounded" src="supply-chain-management_white.png" alt="" />
          </div>
        </div>
      </div>
            <br/>
            <br/>
      <div className="row">
        <div className="col-4">
          <div className="text-right">
          <img className="h-25 w-50 rounded" src="deal_white.png" alt="" />
          </div>
        </div>
        <div className="col-6">
          <h2 className="text-white font-weight-bolder" ><b>Mergers and Acquisitions (M&A)</b></h2>
          <h4 className="text-white font-weight-lighter">
            
            The Double Extortion Platform is a vital resource for enterprises, financial institutions, and holding companies engaged in both local and global Mergers and Acquisitions activities. It caters to the imperative of accurately gauging the <b>genuine value</b> of target companies while factoring in their historical <b>performance in cybersecurity</b>. This proactive approach acts as a shield, safeguarding investments against potential erosions in competitiveness.
            </h4>
        </div>
      </div>
            <br/>
            <br/>
            
      <div className="row ">
        <div className="col-12">
          <h1 className="text-white text-center font-weight-bold"><b>About Us</b></h1>
          <h4 className="text-white text-left font-weight-lighter">
          In 2020, a visionary research initiative took root – The Double Extortion Project. Our humble beginnings were fueled by a resounding need: to illuminate the often-unseen realm of cybersecurity and its profound reverberations across organizations. What started as an idea soon evolved into a resolute mission.<br/><br/>

The echoes of our efforts have reverberated globally. Esteemed publications like CyberNews and La Repubblica have spotlighted our groundbreaking endeavors, underscoring our commitment to shaping a more secure digital landscape. Fast forward to today, and we stand as a beacon of empowerment for companies navigating the intricate pathways of cyber risk, such as leading Insurers and advanced Fintech companies. At the core of our philosophy lies the belief that <b>knowledge is armor, and awareness is the foundation of resilience</b>.<br/><br/>

Double Extortion Platform has meticulously crafted an arsenal of tools and insights designed to empower businesses to navigate the ever-evolving cyber threat landscape. Our mission is clear: to equip decision-makers with the foresight they need to make informed choices, secure their digital assets, and fortify their strategies against potential breaches.<br/><br/>

          </h4>
        </div>
        <div className="col-12">

        </div>
      </div>
            <br/>
            <br/>
            <div className="row ">
        <div className="col-12">
          <h3 className="text-white text-center font-weight-bold"><b>Step into a realm where Cybersecurity is not a barrier but a gateway to Growth</b></h3>
          <h4 className="text-white text-center font-weight-light">Connect with us to unleash the growth potential of cyber aware decision-making.</h4> 


          <div className='d-flex justify-content-center'>
            <a href="https://us1.list-manage.com/contact-form?u=c51dfebb743a50385261dba65&form_id=a53ccc372eb7f62abd40402bae4b47c1" target='blank'>
            <button className="button-53"><b>ASK FOR OTHER DATASET AND PLATFORM ACCESS</b></button>
            </a>
          </div>

        </div>
        <div className="col-6">
        </div>
      </div>
            <br/>
            <br/>
      </div>
    </div>
    
    )
  }
}

export default Land
