import React, { Component, useRef } from 'react'
import MuiAlert from '@material-ui/lab/Alert';

import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar'
import TextField from '@material-ui/core/TextField';
import ReactJson from 'react-json-view'

function checkIsValidDomain(domain) {
  var re = new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/i);
  return domain.match(re) ? true : false;
}



const FileUploader = ({ handleFile }) => {
  const hiddenFileInput = useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    handleFile(fileUploaded);
  };
  return (
    <i style={{color:'#787171'}} onClick={handleClick} className="text-white nc-icon nc-cloud-upload-94">
    <input
        type="file"
        onChange={handleChange}
        ref={hiddenFileInput}
        style={{ display: "none" }} // Make the file input element invisible
      />
    </i>
  );
};
const downloadFile = ({ data, fileName, fileType }) => {
  // Create a blob with the data we want to download as a file
  const blob = new Blob([data], { type: fileType })
  // Create an anchor element and dispatch a click event on it
  // to trigger a download
  const a = document.createElement('a')
  a.download = fileName
  a.href = window.URL.createObjectURL(blob)
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  })
  a.dispatchEvent(clickEvt)
  a.remove()
}


class Report extends Component {

  constructor(props) {
    super(props)
    this.state = {
      reportName:       window.localStorage.getItem('report_name') ?? 'Unnamed Report',
      reportCustomer:   window.localStorage.getItem('report_customer') ?? 'Customer',
      recipientsText:   window.localStorage.getItem('report_recipientsText')??'',
      reportQuery:      JSON.parse(window.localStorage.getItem('report_query')) ?? {},
      recipients:       JSON.parse(window.localStorage.getItem('report_recipients')) ?? [],
      queryData:        JSON.parse(window.localStorage.getItem('report_queryDataRaw')) ?? [],
      results: {},
      errorOpen:false,
      errorMessage:``,
      waitingResults: false
    }
  }

  componentDidMount() {

  }
  setReportCustomer = (reportCustomer) => {
    window.localStorage.setItem('report_customer', reportCustomer);
    this.setState({ reportCustomer: reportCustomer });
  }
  setReportName = (reportName) => {
    window.localStorage.setItem('report_name', reportName);
    this.setState({ reportName: reportName });
  }
  setReportQuery = (queryData) => {
    const reportQuery=[];
    queryData.forEach(r=>{
      const record={}
      record.name=r.name.trim();
      record.keyw=r.keyw.split(',').map(s=>s.trim());
      record.keyw=record.keyw.filter(s=>s&&s.length>0).join(',');
      record.domains=r.domains.split(',').map(s=>s.trim());
      record.domains=record.domains.filter(s=>/^(?:(?:[a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,})$/.test(s)).join(',');
      reportQuery.push(record);
    })
    window.localStorage.setItem('report_query', JSON.stringify(reportQuery)); 
    this.setState({ reportQuery: reportQuery });
  }
  setRecipients = (recipients) => {
    let rl = recipients.split(',').map(s => s.trim());
    rl=rl.filter(s => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(s));

    window.localStorage.setItem('report_recipients', JSON.stringify(rl));
    window.localStorage.setItem('report_recipientsText', recipients);

    this.setState({ recipients: rl, recipientsText: recipients });
  }
  addNewOrg = () => {
    const queryData = this.state.queryData;
    queryData.push({ name: '', keyw: '', domains: '' })
    this.setState({ queryData: queryData })
  }
  handleInputChange = (index, field, value) => {
    const data=this.state.queryData;
    const newData = [...data];
    newData[index][field] = value;
    this.setReportQuery(newData);
    window.localStorage.setItem('report_queryDataRaw',JSON.stringify(newData))
    this.setState({ queryData: newData });
  }
  keyPress = (e) => {
    if (e.keyCode == 13) {
      console.log('value', e.target.value);
      if (!this.state.waitingResults) {
        return this.handleSubmit();
      }
    }
  }

  handleFile = async (fileUploaded)=>{
    if (!fileUploaded){ return}
    const data=await fileUploaded.text();
    const jdata= JSON.parse(data);
    this.setReportQuery(jdata);
    window.localStorage.setItem('report_queryDataRaw',JSON.stringify(jdata))
    this.setState({ queryData: jdata });
    return;
  }


  clearQueryReport=async()=>{
    this.setReportQuery([]);
    window.localStorage.setItem('report_queryDataRaw',JSON.stringify([]))
    this.setState({ queryData: [] });
  }

  handleSubmit = async () => {

    this.setState({
      waitingResults: true,
      errorOpen:false,
      errorMessage:``
    });

    const apiEndpoint = window.localStorage.getItem('apiEndpoint')?? "https://api.eu-ep1.doubleextortion.com/v1";
    const apiKey = window.localStorage.getItem('apiKey');
    const token = window.sessionStorage.getItem('token');
    const body = {};
    const headers = {};

    const searchType = this.state.searchType;

    headers['Authorization'] = token;
    headers['Content-Type'] = 'application/json';
    headers['X-Api-Key'] = apiKey;

    try {

      body.customer = this.state.reportCustomer;
      body.name = this.state.reportName;
      body.emails = this.state.recipients;
      body.orgs = this.state.reportQuery;

      console.log(`[-] querying to "${`${apiEndpoint}/dbtr/report`}":`, body)
      fetch(`${apiEndpoint}/dbtr/report`, {
        method: 'POST',
        headers: { ...headers },
        body: JSON.stringify(body),
        mode: 'cors'
      })
        .then(async (response) => {

          console.log(`[+] results retrieved:`, response)

          const data = await response.json();
          console.debug(`[+] data retrieved:`, data)

          this.setState({
            waitingResults: false,
            results: data
          });

        })
        .catch(async (err) => {
          console.log(`[!] results error:`, err)
          this.setState({
            waitingResults: false,
            results: { Error: err?.message },
            errorOpen:true,
            errorMessage:`Error while retrieving data: ${err.message}`
          });

        });

    } catch (err) {
      console.error(`[!] Something wrong while retrieving data:`, err);
      this.setState({
        waitingResults: false,
        errorOpen:true,
        errorMessage:`Error while retrieving data: ${err.message}`
      });

    }



  };

  exportToJson = async () => {
    
    downloadFile({
      data: JSON.stringify(this.state.queryData),
      fileName: `report_config-${new Date().toISOString()}.json`,
      fileType: 'text/json',
    })
  }

  render() {
    const token = window.sessionStorage.getItem('token') || null
    const apiKey = window.sessionStorage.getItem('apikey') || null
    const reportCustomer = this.state.reportCustomer;
    const reportName = this.state.reportName;
    const reportRecipients = this.state.recipients;
    const recipientsText = this.state.recipientsText;
    const reportQuery = this.state.reportQuery;
    const results = this.state.results;
    const queryData = this.state.queryData??[];

    return (
      <div className="content">

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={this.state.waitingResults}
          onClose={() => { }}
          message='Retrieving Results...'
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={this.state.errorOpen}
          onClose={() => { }} >
           <MuiAlert elevation={6} variant="filled" severity="error">{this.state.errorMessage}</MuiAlert>
        </Snackbar>
        <div className="container-fluid" >

          <div className="row">
            <div>
              <br />
              <h3 className="card-subtitle h3 text-white">Report Page</h3>
            </div>
            <div className="card-body text-white text-justify">
            The Report page functions as the user interface for the <b>Report API</b>. With the Report API at your disposal, you have the ability to create in-depth reports encompassing all the events related to a specific group of companies, an industry, or even an entire country.
            </div>
          </div>

          <div className="row">
            <div>
              <br />
              <h3 className="card-subtitle h6 text-white">Report Task Parameters</h3>
            </div>
          </div>
          <hr style={{ backgroundColor: '#a05b5b', width: '100%' }} ></hr>
          <div className="row">

            <div className="col-sm">
              <div className="message text-white">

                <div className='row align-items-center'>
                  <div className='col-sm-3'>Customer Name:</div>
                  <div className='col-sm'>

                    <TextField style={{
                      backgroundColor: 'white', border: '2px solid #a05b5b', width: '100%',
                      '&:hover': { border: '5px solid red' },
                    }} id="customer-text" label="" variant="outlined" value={reportCustomer} onChange={(e) => this.setReportCustomer(e.target.value)} />

                  </div>
                </div>

                <div className='row align-items-center'>
                  <div className='col-sm-3'>Report Name:</div>
                  <div className='col-sm'>

                    <TextField style={{
                      backgroundColor: 'white', border: '2px solid #a05b5b', width: '100%',
                      '&:hover': { border: '5px solid red' },
                    }} id="name-text" label="" variant="outlined" value={reportName} onChange={(e) => this.setReportName(e.target.value)} />

                  </div>
                </div>

                <div className='row align-items-center'>
                  <Tooltip title='List of destination emails separated with ","'>
                    <div className='col-sm-3'>Recipients:</div>
                  </Tooltip>
                  <div className='col-sm'>


                    <TextField style={{
                      backgroundColor: 'white', border: '2px solid #a05b5b', width: '100%',
                      '&:hover': { border: '5px solid red' },
                    }} id="recipient-text" label="" variant="outlined" value={recipientsText} onChange={(e) => this.setRecipients(e.target.value)} />

                  </div>
                </div>

              </div>
            </div>
            <div className="col-sm-6">
              <div className="message text-white">

                <div className="row align-items-center">
                  <button disabled={this.state.waitingResults} style={{ width: '100%' }} className="button-53 text-uppercase" onClick={this.addNewOrg}>Add Organization</button>
                </div>
                <br />
                <div className="row align-items-right">
                  <div className='col text-center'>
                  <Tooltip title='Load configuration from file'><div><small>Load </small><FileUploader handleFile={this.handleFile}/></div></Tooltip>
                  </div>
                  <div className='col text-center'>
                  <Tooltip title='Export configuration to file'><div><small>Export </small><i style={{color:'#787171'}} onClick={this.exportToJson} className="text-white nc-icon nc-cloud-download-93"></i></div></Tooltip>
                  </div>
                  <div className='col text-center'>
                  <Tooltip title='Clear current configuration'><div><small>Clear </small><i style={{color:'#787171'}} onClick={this.clearQueryReport} className="text-white nc-icon nc-simple-remove"></i></div></Tooltip>
                  </div>
                </div>
                <br />

                <div className="row align-items-center">
                  <div className="col">

                    <div  className="overflow-auto" style={{ overflow: "scroll",overflowX: "hidden", maxHeight: "300px"}}>
                      {queryData.map((item, index) => (
                        <div key={index} className="row align-items-center">
                          <div className="col-sm-2">
                          <Tooltip title='Configure the keywords and domains for your organizational perimeter (use "," as separator)'>
                            <span className="text-white text-align-right small">Org.{`${index+1}`}</span>
                            </Tooltip>
                          </div>
                          <div className="col">
                            <TextField style={{ backgroundColor: 'white', border: '2px solid #a05b5b', width: '100%', '&:hover': { border: '5px solid red' }, }}
                              type="text"
                              placeholder="Name"
                              value={item.name}
                              onChange={e => this.handleInputChange(index, 'name', e.target.value)}
                            />
                            <TextField style={{ backgroundColor: 'white', border: '2px solid #a05b5b', width: '100%', '&:hover': { border: '5px solid red' }, }}
                              type="text"
                              placeholder="Keywords"
                              value={item.keyw}
                              onChange={e => this.handleInputChange(index, 'keyw', e.target.value)}
                            />
                            <TextField style={{ backgroundColor: 'white', border: '2px solid #a05b5b', width: '100%', '&:hover': { border: '5px solid red' }, }}
                              type="text"
                              placeholder="Domains"
                              value={item.domains}
                              onChange={e => this.handleInputChange(index, 'domains', e.target.value)}
                            />
                          </div>
                        </div>
                      ))}
                    </div>


                  </div>
                </div>
                <br />



              </div>
            </div>
          </div>


          <div className="row justify-content-center">
            <div className="col ">
              <br />
              <button disabled={this.state.waitingResults} style={{ width: '100%' }} className="button-53 text-uppercase" onClick={this.handleSubmit}>Generate Report</button>
            </div>
          </div>


          <br />

          <div className="row">
            <div>
              <br />
              <h3 className="card-subtitle h6 text-white">Results</h3>
            </div>
          </div>
          <hr style={{ backgroundColor: '#a05b5b', width: '100%' }} ></hr>

          <div className="row">
            <div className='col'>
              <div className='card'>

                <ReactJson name={false} iconStyle='circle' collapsed={2} displayDataTypes={false} src={results} />

              </div>
            </div>
          </div>


        </div>
      </div >
    )
  }
}

export default Report
