import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar';


class About extends Component {

  constructor(props){
    super(props)
    this.state={}
  }

  componentDidMount(){

  }



  render() {

    return (
      <div className="content">      
      <div className="container-fluid" >

         
        <div className="row">
          <div className="col">
            <div className="card w-100"> 
              <div className="card-header">
                
              </div>
              <div className="card-body">
              <div className="container-grid">
                <div className="row ">
                  <div className="col-xs-4 align-self-center text-center">
                    <img className="rounded-circle w-75" src="me.jpeg" alt="Luca Mella Profile Pic" />
                  </div>
                  <div className="col-sm-8">

                    <div className="message ">
                    <h4 className="card-title">About the Author</h4><br/>
                    <h5 className="card-subtitle h6">Luca Mella, M.Eng. </h5>
                    <br/>
                    <p className="card-text">
                      Luca is a Cyber Security professional with a deep passion for hacking and an intimate curiosity for the digital world. 
                      <br/>
                      Luca has created the first Italian CERT unit certified by <a href="https://www.trusted-introducer.org/">Trusted Introducer</a>, and overseen malware research programs. He also teaches how to analyze modern malware at <a href="https://master.unibo.it/cybersecurity/en/programme">University of Bologna</a>.  
                      <br/><br/>
                      He is a former member of the <a href="https://ctftime.org/team/520">ANeSeC</a> CTF team, one of the firsts Italian cyber war-game teams born back in 2011.  
                      <br/><br/>
                      In 2019, Luca was mentioned as one of the "32 Influential Malware Research Professionals".
                    </p>
                    </div>

                  </div>
                </div>
              </div>
              
                
                
              </div>
            </div>
          </div>
        </div>
            
      <div className="row">
      <div className="col">

        <div className="content">
        <div className="text-left">
          <h4>Other Things Luca is Passionate About</h4>
        </div>
        <div className="container">
          <div className="card-columns">
            <div className="card">
              <a href="https://www.privacy-network.it/" target="_blank">
              <img className="card-img-top" src="https://764000.smushcdn.com/2258058/wp-content/uploads/2020/06/HeroHomeImage.png?lossy=0&strip=1&webp=1" alt="Privacy Network"/>
              <div className="card-body">
                <h5 className="card-title">Privacy & Digital Ethics</h5>
                <p className="card-text">
                  
                </p>
                <p className="card-text">Privacy-Network Member</p>
            </div>
              </a>
            </div>
            <div className="card">
              <a href="https://www.cybersecurity360.it/giornalista/luca-mella/" target="_blank">
              <img className="card-img-top" src="https://pbs.twimg.com/profile_images/983704933940809728/nwnY2tSq.jpg" alt="CyberSecurity360"/>
              <div className="card-body">
                <h5 className="card-title">CyberSecurity Divulgation</h5>
                <p className="card-text">CyberSecurity360 Writer</p>
                <p className="card-text"></p>
              </div>
              </a>
            </div>
            <div className="card">
              <a href="https://cybersecurityprofiles.review/" target="_blank">
              <img className="card-img-top" src="https://cybersecurityprofiles.review/screen.png" alt="NICE Workforce mapper"/>
              <div className="card-body">
                <h5 className="card-title">Cyber Security Workforce</h5>
                <p className="card-text">Author of "I'm i NICE", a NICE framework Skill/Knowledge mapper </p>
                <p className="card-text"></p>
              </div>
              </a>
            </div>
            <div className="card">
              <a href="https://www.esa.int/Education/BEXUS_18_19_balloon_launch_campaign_about_to_start" target="_blank">
              <img className="card-img-top" src="http://www.esa.int/var/esa/storage/images/esa_multimedia/images/2007/12/rexus_bexus_logo/10290930-5-eng-GB/REXUS_BEXUS_Logo_pillars.jpg" alt="BEXUS 18 ESA"/>
              <div className="card-body">
                <h5 className="card-title">Space & Software Engineering</h5>
                <p className="card-text">Former OBDH engineer for ESAs Bexus 18 launch (A5 Unibo team)</p>
                <p className="card-text"></p>
              </div>
              </a>
            </div>
            <div className="card">
              <a href="https://github.com/luca-m/emotime" target="_blank">
              <img className="card-img-top" src="https://github.com/luca-m/emotime/blob/master/report/images/exampl_happy2.png?raw=true" alt="Emotime"/>
              <div className="card-body">
                <h5 className="card-title">Artificial Intelligence and Machine Learning</h5>
                <p className="card-text">Co-Author of the Emotime project</p>
                <p className="card-text"></p>
              </div>
              </a>
            </div>
            <div className="card">
              <a href="https://www.redhotcyber.com/" target="_blank">
              <img className="card-img-top" src="https://static.wixstatic.com/media/b939cf_43500df3f1d84d94ac20286c0378fddc~mv2.png/v1/fill/w_151,h_144,al_c,usm_0.66_1.00_0.01,enc_auto/Logo%20in%20Cerchio%20v0_5%20(Transparent).png" alt="Emotime"/>
              <div className="card-body">
                <h5 className="card-title">Author for Red Hot Cyber</h5>
                <p className="card-text">Ransomware Data Room column author</p>
                <p className="card-text"></p>
              </div>
              </a>
            </div>

    </div>

    </div>
  </div>
</div>
      
      </div>
      </div>
      </div>
    )
  }
}

export default About
