import React, { Component } from 'react'
import Plot from 'react-plotly.js';
import MuiAlert from '@material-ui/lab/Alert';


import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar'

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import Checkbox from '@material-ui/core/Checkbox';

import { DataGrid } from '@material-ui/data-grid';

import { CSVLink, CSVDownload } from "react-csv";


const columns = [{
  dataField: 'date',
  text: 'Date'
}, {
  dataField: 'victim',
  text: 'Victim'
}, {
  dataField: 'sector',
  text: 'Sector'
}, {
  dataField: 'actor',
  text: 'Actor'
}, {
  dataField: 'country',
  text: 'Country'
},{
  dataField: 'revenue',
  text: 'Reventue'
},{
  dataField: 'amount',
  text: 'Amount'
}
];

class PrivateDataTable extends Component {

  constructor(props) {
    super(props)

    if (!window.localStorage.getItem('table_ts') || isNaN(new Date(window.localStorage.getItem('table_ts'))) ){
      window.localStorage.setItem('table_ts',  new Date(new Date().setMonth(new Date().getMonth()-1)).toISOString() )
    }
    if (!window.localStorage.getItem('table_te') || isNaN(new Date(window.localStorage.getItem('table_te'))) ){
      window.localStorage.setItem('table_te',  new Date().toISOString()  )
    }

    this.state = {
      ts:     window.localStorage.getItem('table_ts'),
      te:     window.localStorage.getItem('table_te') ,
      topN:    window.localStorage.getItem('table_topn') ,
      
      industries:    window.localStorage.getItem('table_industries') ,
      countries:    window.localStorage.getItem('table_countries') ,
      actors:    window.localStorage.getItem('table_actors') ,
    
      fullName:   Boolean(window.localStorage.getItem('table_fullName') ?? false),

      dataset:        window.localStorage.getItem('table_dataset') ?? 'ext',
      
      errorOpen:false,
      errorMessage:'',

      results: [],
      waitingResults: false
    }
  }

  compoentDidMount() {

  }

  setTopN = (value) => {
    window.localStorage.setItem('table_topn', value);
    this.setState({ topN: value });
  }
  setTs = (value) => {
    window.localStorage.setItem('table_ts', value.toISOString());
    this.setState({ ts: value.toISOString() });
  }
  setTe = (value) => {
    window.localStorage.setItem('table_te', value.toISOString());
    this.setState({ te: value.toISOString() });
  }
  setFullName = (value) => {
    window.localStorage.setItem('table_fullName', value?true:false );
    this.setState({ fullName: value?true:false  });
  }
  setIndustries = (value) => {
    window.localStorage.setItem('table_industries', value);
    this.setState({ industries: value });
  }
  setCountries = (value) => {
    window.localStorage.setItem('table_countries', value);
    this.setState({ countries: value });
  }
  setActors = (value) => {
    window.localStorage.setItem('table_actors', value);
    this.setState({ actors: value });
  }
  setDataset = (dataset) => {
    window.localStorage.setItem('table_dataset', dataset);
    this.setState({ dataset: dataset });
  }

  handleSearch = async () => {

    this.setState({
      waitingResults: true,
      errorOpen:false,
      errorMessage:''
    });

    const apiEndpoint = window.localStorage.getItem('apiEndpoint')?? "https://api.eu-ep1.doubleextortion.com/v1";
    const apiKey = window.localStorage.getItem('apiKey');
    const token = window.sessionStorage.getItem('token');

    const body = {};
    const headers = {};

    headers['Authorization'] = token;
    headers['Content-Type'] = 'application/json';
    headers['X-Api-Key'] = apiKey;

    try {

      const url = new URL(`${apiEndpoint}/dbtr/privlist`);
      const params = new URLSearchParams();
      params.append('ts', new Date(this.state.ts).toISOString().split('T')[0] );
      params.append('te', new Date(this.state.te).toISOString().split('T')[0] );
      params.append('dset', this.state.dataset );
      if (this.state.industries && this.state.industries!=''){ params.append('industryFilter', this.state.industries ); }
      if (this.state.countries && this.state.countries!='')  { params.append('countryFilter', this.state.countries );}
      if (this.state.actors && this.state.actors!='')        { params.append('actorFilter', this.state.actors );}
      params.append('full', this.state.fullName?true:false );

      url.search = params.toString();
      const finalURL = url.toString();

      console.log(`[-] querying to "${`${apiEndpoint}/dbtr/privlist`}":`, params.toString())
      fetch(finalURL, {
        method: 'GET',
        headers: { ...headers },
        mode: 'cors'
      })
        .then(async (response) => {
          console.log(`[+] results retrieved:`, response)
          const data = await response.json();
          console.debug(`[+] data retrieved:`, data)
          this.setState({
            waitingResults: false,
            results: data
          });

        })
        .catch(async (err) => {
          console.log(`[!] results error:`, err)
          this.setState({
            waitingResults: false,
            results: { Error: err?.message },
            errorOpen:true,
            errorMessage:`Error while retrieving data: ${err.message}`
          });

        });

    } catch (err) {
      console.error(`[!] Something wrong while retrieving data:`, err);
      this.setState({
        waitingResults: false,
        errorOpen:true,
        errorMessage:`Error while retrieving data: ${err.message}`
      });

    }
  };

  preparePie = (results,field)=>{
    const pie={
      labels:[],
      series:[]
    };
    const data = results.map(e => e[field] || 'Unspecified');
    const dataCount = {};
    data.forEach(s => {
      if (dataCount[s]) { dataCount[s] += 1 } else { dataCount[s] = 1 }
    })
    var tmp_data = []
    for (let k in dataCount) { tmp_data.push({ s: k, c: dataCount[k] }) }
    tmp_data = tmp_data.sort((a, b) => (a.c < b.c) ? 1 : ((b.c < a.c) ? -1 : 0))
    tmp_data.forEach(e => { pie.labels.push(e.s); pie.series.push(e.c) })
    return pie;
  }

  render() {
    const industries = this.state.industries;
    const countries = this.state.countries;
    const actors = this.state.actors;

    const fullName = this.state.fullName;
    
    const ts = this.state.ts;
    const te = this.state.te;
    
    const topN = this.state.topN;
    const dataset = this.state.dataset;

    let enabled_gourps=[]
    try{
      const access_token_p = JSON.parse(window.atob(window.sessionStorage.getItem('access_token').split('.')[1], 'base64').toString());
      enabled_gourps = access_token_p["cognito:groups"]  
    } catch (ex){
   
    }
    
    let results=[];
    if (Array.isArray(this.state.results)){
      results = this.state.results
    } else {
      results.push([{date:`${new Date().toISOString()}`,actor:'Error',victim:'Something wrong', sector:'during data',country:'retrieval'}])
    }
    
    let data =JSON.parse(JSON.stringify(results));
    data = data.map((d, i) => {
      d.idx = i
      d.date = (d.date||'').split('T')[0]
      return d;
    });

    /// prepare pie

    const dataPie  = this.preparePie(results,'sector');
    const dataMap1 = this.preparePie(results,'country');
    const dataPie2 = this.preparePie(results,'actor');

    /*  */
    return (
      <div className="content">
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={this.state.waitingResults}
          onClose={() => { }}
          message='Retrieving Results...'
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={this.state.errorOpen}
          onClose={() => { }} >
           <MuiAlert elevation={6} variant="filled" severity="error">{this.state.errorMessage}</MuiAlert>
        </Snackbar>
        <div className="container-fluid">

          <div className="row">
              <div>
                <br />
                <h3 className="card-subtitle h3 text-white">Private Data Table</h3>
              </div>
          </div>
          <div className="row">
              <div className="card-body text-white text-justify">
              The Private Data Table is the user interface of the DEP Platform's <b>PrivList API</b>. The PrivList API allows you to extract events in the form of a short list summarizing the principal information about each event, including the actor, organization involved, sector, and nationality.
              </div>
          </div>


          <div className="row">
            <div>
              <br />
              <h3 className="card-subtitle h6 text-white">Data Table Parameters</h3>
            </div>
          </div>
          <hr style={{ backgroundColor: '#a05b5b', width: '100%' }} ></hr>
          <div className="row">

            <div className="col-sm">
              <div className="message text-white">

                <div className='row align-items-center'>
                  <div className='col-sm'>
                    <TextField
                      style={{
                        backgroundColor: 'white', border: '2px solid #a05b5b', width: '100%',
                        '&:hover': { border: '5px solid red' },
                      }}
                      id="ts"
                      label="Time Start"
                      type="date"
                      format={'YYYY/MM/DD'}
                      defaultValue={new Date(ts).toISOString().split('T')[0]}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => this.setTs(e.target.valueAsDate)}
                    />
                  </div>
                  
                  <div className='col-sm'>
                    <TextField
                      style={{
                        backgroundColor: 'white', border: '2px solid #a05b5b', width: '100%',
                        '&:hover': { border: '5px solid red' },
                      }}
                      id="te"
                      label="Time End"
                      type="date"
                      format={'YYYY/MM/DD'}
                      defaultValue={new Date(te).toISOString().split('T')[0]}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => this.setTe(e.target.valueAsDate)}
                    />
                  </div>
                </div>
                <br/>
                <div className='row align-items-center'>
                  <div className='col-sm'>Dataset:</div>
                  <div className='col-sm'>

                    <Select
                      style={{ backgroundColor: 'white', width: '100%', textAlign: 'center' }}
                      labelId="dSet"
                      id="qSet-selector"
                      value={dataset}
                      onChange={(e) => this.setDataset(e.target.value)} >
                       {( enabled_gourps.indexOf('extortions-users')!=-1  || enabled_gourps.indexOf('full-access')!=-1?<MenuItem value='ext'>Extortions</MenuItem>:<div></div>)}
                       {( enabled_gourps.indexOf('opennews-users')!=-1  || enabled_gourps.indexOf('full-access')!=-1?<MenuItem value='nws'>OpenNews</MenuItem>:<div></div>)}
                       {( enabled_gourps.indexOf('privacy-users')!=-1  || enabled_gourps.indexOf('full-access')!=-1?<MenuItem value='prv'>Privacy</MenuItem>:<div></div>)}
                       {( enabled_gourps.indexOf('vandalism-users')!=-1  || enabled_gourps.indexOf('full-access')!=-1?<MenuItem value='vnd'>Vandalism</MenuItem>:<div></div>)}
                       {( enabled_gourps.indexOf('forum-users')!=-1  || enabled_gourps.indexOf('full-access')!=-1?<MenuItem value='frm'>Underground</MenuItem>:<div></div>)}
                       {( enabled_gourps.indexOf('ddos-users')!=-1  || enabled_gourps.indexOf('full-access')!=-1?<MenuItem value='dds'>DDoS</MenuItem>:<div></div>)}
                    </Select>

                  </div>
                </div>

                <div className='row align-items-center'>
                  <div className='col-sm'>
                  <Tooltip title="Enable legal name retrieval (if available)"><span className="text-white">Full Name:</span></Tooltip>
                  </div>
                  <div className='col-sm'>
                    <Checkbox
                            size="medium"
                            style={{ color: 'white' }}
                            inputProps={{ 'aria-label': 'checkbox with small size' }}
                            checked={fullName} onChange={(e) => this.setFullName(e.target.checked)}
                          />
                  </div>
                </div>


                <div className='row align-items-center'>
                  <div className='col-sm'>Top</div>
                  <div className='col-sm'>
                    <Slider
                      value={topN}
                      aria-labelledby="discrete-slider"
                      valueLabelDisplay="auto"
                      step={1}
                      
                      min={3}
                      max={20}
                      style={{ color: '#a05b5b' }}
                      onChange={(e, value) => this.setTopN(value)}
                    />
                  </div>
                </div>



              </div>
            </div>
            <div className="col-sm">
              <div className="message text-white">

                  <div className="row align-items-center">
                      <div className="col-2">
                      <Tooltip title="Comma separated list of Industries"><span className="text-white"> Industry Filter:</span></Tooltip><br />
                      </div>
                      <div className="col">
                        <TextField style={{
                          backgroundColor: 'white', border: '2px solid #a05b5b', width: '100%',
                          '&:hover': { border: '5px solid red' },
                        }} id="keyword-text" label="" variant="outlined" value={industries}  onKeyDown={this.keyPress} onChange={(e) => this.setIndustries(e.target.value)} />
                      </div>
                  </div>
                  <div className="row align-items-center">
                      <div className="col-2">
                      <Tooltip title="Comma separated list of Industries"><span className="text-white">Country Filter:</span></Tooltip><br />
                      </div>
                      <div className="col">
                        <TextField style={{
                          backgroundColor: 'white', border: '2px solid #a05b5b', width: '100%',
                          '&:hover': { border: '5px solid red' },
                        }} id="keyword-text" label="" variant="outlined" value={countries} onKeyDown={this.keyPress} onChange={(e) => this.setCountries(e.target.value)} />
                      </div>
                  </div>
                  <div className="row align-items-center">
                      <div className="col-2">
                      <Tooltip title="Comma separated list of Actors"><span className="text-white">Actor Filter:</span></Tooltip><br />
                      </div>
                      <div className="col">
                        <TextField style={{
                          backgroundColor: 'white', border: '2px solid #a05b5b', width: '100%',
                          '&:hover': { border: '5px solid red' },
                        }} id="keyword-text" label="" variant="outlined" value={actors} onKeyDown={this.keyPress} onChange={(e) => this.setActors(e.target.value)} />
                      </div>
                  </div>
              
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col ">
              <br />
              <button disabled={this.state.waitingResults} style={{ width: '100%' }} className="button-53 text-uppercase" onClick={this.handleSearch}>Search</button>
            </div>
          </div>


          <br />

          <div className="row">

            <div className="col-md lg">

              <div className="card">
                <div className="card-header ">
                  <h4 className="card-title">Top {`${topN}`} Impacted Industries</h4>
                  <p className="card-category">Organizations</p>
                </div>
                <div className="card-body">
                  <Plot
                    className="w-100"
                    data={[
                      {
                        name: 'sectors',
                        values: dataPie.series.slice(0, topN),
                        labels: dataPie.labels.slice(0, topN),
                        type: 'pie',
                        colorscale: 'Prism',
                        color: 'Prism',
                        pull: 0.05,
                        automargin: true,
                        marker: {
                          line: { width: 0.5 },
                          colors:
                            ["#fff7ec", "#fff7ec", "#fff7ec", "#fee8c8", "#fdd49e", "#fdbb84", "#fc8d59", "#ef6548", "#d7301f", "#b30000", "#7f0000"].reverse()
                              .map(hex => {
                                var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
                                return result ? `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)})` : '#000';
                              })
                        }
                      },
                    ]}
                    layout={{
                      autosize: true, font: { size: 10 }, showlegend: true,
                      legend: { xanchor: 'center', x: 0.5, orientation: 'h' }
                    }}
                    config={{ responsive: false, displayModeBar: false, modeBarButtonsToRemove: ['pan2d', 'select2d', 'lasso2d', 'resetScale2d', 'zoomOut2d'] }}
                  />
                  <hr />
                  <small className="text-center">The industry plot shows the distribution of events among the top 10 impacted business sectors. 
                    Currently we observed <b>{results.length}</b> events across <b>{dataPie.labels.length}</b> industries. </small>
                </div>
              </div>
            </div>

            <div className="col-md lg">

              <div className="card">
                <div className="card-header ">
                  <h4 className="card-title">Involved Countries</h4>
                  <p className="card-category">Regions</p>
                </div>
                <div className="card-body">

                  <Plot
                    className="w-100"
                    data={[
                      {
                        type: 'choropleth',
                        locationmode: 'country names',
                        locations: dataMap1.labels,
                        z: dataMap1.series,
                        autocolorscale: false,
                        reversescale: true,

                        colorscale: [
                          [0.0, '#b30000'], [0.4, '#fc8d59'], [0.75, '#fdd49e'],
                          [0.8, '#fdd49e'], [0.9, '#fdd49e'],
                          [0.95, '#fee8c8'], [1, '#fff7ec']],
                        colorbar: {

                          title: 'Events',
                          thickness: 25
                        },
                      },

                    ]}
                    layout={{
                      autosize: true, font: { size: 10 }, showlegend: false,
                      geo: {

                        projection: {
                          type: 'orthographic'
                        }
                      },

                    }}
                    config={{
                      responsive: false,
                      displayModeBar: false,
                      modeBarButtonsToRemove: ['pan2d', 'select2d', 'lasso2d', 'resetScale2d', 'zoomOut2d']
                    }}
                  />

                  <hr />
                  <small className="text-center">The Countries plot shows the distribution of events among the involved nations. 
                    Currently we observed <b>{results.length}</b> events across <b>{dataMap1.labels.length}</b> countries. </small>
                </div>
              </div>
            </div>

            <div className="col-md lg">
              <div className="card">
                <div className="card-header ">
                  <h4 className="card-title">Top {`${topN}`} Actors</h4>
                  <p className="card-category">Actors</p>
                </div>
                <div className="card-body">
                  <Plot
                    className="w-100 mx-auto"

                    data={
                      [
                        {
                          name: 'actors',
                          values: dataPie2.series.slice(0, topN),
                          labels: dataPie2.labels.slice(0, topN),
                          type: 'pie',
                          mode: 'markers',
                          marker: {
                            line: { width: 0.5 },
                            colors:
                              ["#fff7ec", "#fff7ec", "#fff7ec", "#fee8c8", "#fdd49e", "#fdbb84", "#fc8d59", "#ef6548", "#d7301f", "#b30000", "#7f0000"].reverse()
                                .map(hex => {
                                  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
                                  return result ? `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)})` : '#000';
                                })
                          },
                          pull: 0.05,
                          automargin: true
                        }
                      ]}
                    layout={{
                      autosize: true, font: { size: 10 },
                      showlegend: true,
                      legend: { xanchor: 'center', x: 0.5, orientation: 'h' }
                    }}
                    config={{ responsive: false, displayModeBar: false, modeBarButtonsToRemove: ['pan2d', 'select2d', 'lasso2d', 'resetScale2d', 'zoomOut2d'] }}
                  />
                  <hr />
                  <small className="text-center">The Actor Statistics plot shows the distribution of the top 10 actors causing most of the observed events. 
                    Currently we observed <b>{dataPie2.labels.length}</b> actors impacting <b>{dataPie.labels.length}</b> industries. </small>
                </div>
              </div>
            </div>
          </div>

          
          <div className="row">
            <div className="col">
              <div className="card w-100">
                <div className="card-header">

                  <div className="row">
                    <div className="col h6 text-right">
                      Events Data Table
                    </div>
                    <div className="col">
                      <CSVLink className="link-dark" filename={`dep_plist-${dataset}-export_${new Date(ts).toISOString().split('T')[0]}-${new Date(te).toISOString().split('T')[0]}.csv`} data={results}><i className="nc-icon nc-cloud-download-93"></i></CSVLink>
                    </div>

                  </div>

                </div>
                <div className="card-body justify-content-center">

                  <div style={{ height: '700px' }} className="w-100 min-vh-100">
                    <DataGrid
                      rows={data.map((e, i) => { e.id = i + 1; return e; })}
                      columns={[{
                        field: 'date',
                        headerName: 'Date',
                        width: 150,
                        editable: false,
                      },
                      {
                        field: 'actor',
                        headerName: 'Actor',
                        width: 150,
                        editable: false,
                      }, 
                      {
                        field: 'victim',
                        headerName: 'Organization',
                        width: 250,
                        editable: false,
                      }, {
                        field: 'sector',
                        headerName: 'Sector',
                        width: 200,
                        editable: false,
                      },
                      {
                        field: 'country',
                        headerName: 'Country',
                        width: 200,
                        editable: false,
                      },{
                        field: 'revenue',
                        headerName: 'Revenue',
                        width: 160,
                        editable: false,
                      },{
                        field: 'amount',
                        headerName: 'Amount',
                        width: 160,
                        editable: false,
                      },
                      {
                        field: 'naics',
                        headerName: 'NAICS Code',
                        width: 160,
                        editable: false,
                      },
                      {
                        field: 'site',
                        headerName: 'Site',
                        width: 160,
                        editable: false,
                      }
                    ]}

                      pageSize={50}
                      rowsPerPageOptions={[50]}

                    />
                  </div>

                </div>

              </div>
            </div>
          </div>


        </div>
      </div>
    )
  }
}

export default PrivateDataTable
