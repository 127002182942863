import React, { Component } from 'react'
import MuiAlert from '@material-ui/lab/Alert';

import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar'

import TextField from '@material-ui/core/TextField';
import TreeView from "react-treeview";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';


function checkIsValidDomain(domain) {
  var re = new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/i);
  return domain.match(re) ? true : false;
}


class Ask extends Component {

  constructor(props) {
    super(props)
    this.state = {
      question: window.sessionStorage.getItem('ask_question') ?? '',
      results: Array.from(JSON.parse(window.sessionStorage.getItem('ask_results') ?? "[]")),
      questions: Array.from(JSON.parse(window.sessionStorage.getItem('ask_questions') ?? "[]")),
      qa: JSON.parse(window.sessionStorage.getItem('ask_qa') ?? "{}"),
      waitingResults: false,
      errorOpen:false,
      errorMessage:``,
      recordsCollapse: {}
    }
  }

  componentDidMount() {

  }

  setQuestion = (q) => {
    window.sessionStorage.setItem('ask_question', q);
    //this.state.questions.push(q);
    //window.sessionStorage.setItem('ask_questions', JSON.stringify(this.state.questions));
    this.setState({ question: q });
  }
  keyPress = (e) => {
    if (e.keyCode == 13) {
      console.log('value', e.target.value);
      if (!this.state.waitingResults) {
        return this.handleSearch();
      }
    }
  }

  clearConversation= ()=>{
    window.sessionStorage.setItem('ask_results',"[]");
    window.sessionStorage.setItem('ask_questions',"[]");
    window.sessionStorage.setItem('ask_qa','{}');
    this.setState({ qa: {}, questions:[], results:[] });

  }

  handleSearch = async () => {

    this.setState({
      waitingResults: true,
      errorOpen:false,
      errorMessage:``,
    });

    const apiEndpoint = window.localStorage.getItem('apiEndpoint')?? "https://api.eu-ep1.doubleextortion.com/v1";
    const apiKey = window.localStorage.getItem('apiKey');
    const token = window.sessionStorage.getItem('token');
    const body = {};
    const headers = {};

    headers['Authorization'] = token;
    headers['Content-Type'] = 'application/json';
    headers['X-Api-Key'] = apiKey;

    try {

      const question = this.state.question;
      const qq = this.state.questions;
      qq.push(question);
      const qIndex = qq.indexOf(question);
      window.sessionStorage.setItem('ask_questions', JSON.stringify(qq));

      console.log(`[-] querying to "${`${apiEndpoint}/dbtr/ask`}":`, body)
      fetch(`${apiEndpoint}/dbtr/ask?${new URLSearchParams({ query: question }).toString()}`, {
        method: 'GET',
        headers: { ...headers },
        mode: 'cors'
      })
        .then(async (response) => {

          console.log(`[+] results retrieved:`, response)

          const data = await response.json();
          console.debug(`[+] data retrieved:`, data)

          const r = this.state.results;
          r.push(data);
          window.sessionStorage.setItem('ask_results', JSON.stringify(this.state.results));
          const qa = this.state.qa;
          qa[qIndex] = data;
          window.sessionStorage.setItem('ask_qa', JSON.stringify(qa));
          this.setState({
            waitingResults: false,
            questions: qq,
            results: r,
            qa
          });

        })
        .catch(async (err) => {
          console.log(`[!] results error (${err})`)
          const r = this.state.results;
          r.push({ Error: err?.message });

          const qa = this.state.qa;
          qa[qIndex] = { Error: err?.message };

          window.sessionStorage.setItem('ask_results', JSON.stringify(this.state.results));
          window.sessionStorage.setItem('ask_qa', JSON.stringify(qa));
          this.setState({
            waitingResults: false,
            questions: qq,
            results: r,
            errorOpen:true,
            errorMessage:`Error while retrieving data: ${err.message}`,
            qa
          });

        });

    } catch (err) {
      console.error(`[!] Something wrong while retrieving data (${err})`);
      const r = this.state.results;
      r.push({ Error: err?.message });
      const qa = this.state.qa;
      qa[qIndex] = { Error: err?.message };
      window.sessionStorage.setItem('ask_results', JSON.stringify(this.state.results));
      window.sessionStorage.setItem('ask_qa', JSON.stringify(qa));
      this.setState({
        waitingResults: false,
        results: r,
        errorOpen:true,
        errorMessage:`Error while retrieving data: ${err.message}`,
        qa
      });

    }


    // this.setState({
    //   results: [
    //     { id: 1, title: 'Sample Result 1', content: 'This is the content of sample result 1.' },
    //     { id: 2, title: 'Sample Result 2', content: 'This is the content of sample result 2.' },
    //   ]
    // });
  };

  treeClick = (i) => {
    console.log(`Clicked on item ${i}`);
    const recordsCollapse = this.state.recordsCollapse;
    if (recordsCollapse[i]) { recordsCollapse[i] = false; }
    else { recordsCollapse[i] = true; }
    this.setState({ recordsCollapse: recordsCollapse });
  };


  render() {
    const question = this.state.question;
    const questions = this.state.questions.toReversed();
    const results = this.state.results.toReversed();
    const qa = this.state.qa;

    return (
      <div className="content">

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={this.state.waitingResults}
          onClose={() => { }}
          message='Thinking...'
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={this.state.errorOpen}
          onClose={() => { }} >
           <MuiAlert elevation={6} variant="filled" severity="error">{this.state.errorMessage}</MuiAlert>
        </Snackbar>
        <div className="container-fluid" >




          <div className="row ">
          <h3 className="card-subtitle h3 text-white">Ask AI Interface</h3>
          <div className="card-body text-white text-justify">
          The ASK AI Interface serves as the user interface for the DEP Platform's impressive <b>Ask API</b>. 
          This advanced Ask API leverages the capabilities of the proprietary "IntelQuery AI," an artificial intelligence entity meticulously trained on the platform's extensive dataset. 
          With this powerful tool, you can interact with IntelQuery AI by asking questions such as <i>"Has company XYZ experienced a security breach?"</i>, <i>"Are there any recent instances of extortion involving XYZ?"</i>, 
          <i>"What specific data has been compromised at XYZ?"</i>, or even <i>"Has XYZ faced any sanctions in Italy?"</i>. 
          Your inquiries yield insightful answers through the intelligence of IntelQuery AI.
          </div>
          </div>
          <br />

          <div className="row">
            <div>
              <br />
              <h3 className="card-subtitle h6 text-white">Question</h3>
            </div>
          </div>
          <hr style={{ backgroundColor: '#a05b5b', width: '100%' }} ></hr>

          <div className="row">

            <div className="col-sm">
              <div className="message text-white">

                <div className='row align-items-center'>

                  <div className='col-sm'>

                    <TextField style={{
                      backgroundColor: 'white', border: '2px solid #a05b5b', width: '100%',
                      '&:hover': { border: '5px solid red' },
                    }} id="question-text" label="" variant="outlined" value={question} onKeyDown={this.keyPress} onChange={(e) => this.setQuestion(e.target.value)} />

                  </div>
                </div>


              </div>
            </div>

          </div>


          <div className="row justify-content-center">
            <div className="col ">
              <br />
              <button disabled={this.state.waitingResults} style={{ width: '100%' }} className="button-53 text-uppercase" onClick={this.handleSearch}>Ask</button>
            </div>
          </div>


          <br />

          <div className="row justify-content-end">
          <br />

              <div className='col'>
              <h3 className="card-subtitle h6 text-white">Conversation <Tooltip title="Clear conversation"><b  onClick={this.clearConversation} className="nc-icon nc-simple-remove"></b></Tooltip></h3>
              </div>              

          </div>
          <hr style={{ backgroundColor: '#a05b5b', width: '100%' }} ></hr>

          <div className=''>
            {questions.map((quest, index) => (
              <div className="flex gap-x-4">
                <div className='row'>
                  <div className='col-1 col-lg-1 align-middle text-right'>
                    <i className="text-white nc-icon nc-single-02 align-middle" />
                  </div>
                  <div className='col'>
                    <p className="mt-1 text-justify text-xs leading-5 text-gray-500 text-white text-clip"><small>Q-{questions.length - index}: {quest}</small></p>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-1 col-lg-1 align-middle text-right'>
                    <img className="h-12 w-12 flex-none rounded-full bg-gray-50" style={{ width: 25 }} src="/logow.svg" alt="" />

                  </div>
                  <div className='col'>
                    <p className="mt-1 text-justify text-xs leading-5 text-gray-500 text-white text-clip">
                      {
                        (qa[questions.length - 1 - index] ?
                          (
                            qa[questions.length - 1 - index]?.answer ?
                              <div>
                                <div>{`${qa[questions.length - 1 - index]?.answer}`}</div><br/>
                                <TreeView
                                  key={questions.length - 1 - index}
                                  nodeLabel={<Paper><Typography><h6 onClick={() => this.treeClick(questions.length - 1 - index)}>{(this.state.recordsCollapse[questions.length - 1 - index]?'[+]':'[-]')} Records</h6></Typography></Paper>}
                                  collapsed={this.state.recordsCollapse[questions.length - 1 - index]}
                                ><Paper><Typography><ol>
                                  {qa[questions.length - 1 - index]?.records.map(entry => <li className="small" ><small>{String(entry).replace('{"$date":"','').replace('"}:','').replace(/\\n/g,' ').replace(/\s+/g,' ').slice(0,220)+' ..'}</small></li>)}
                                </ol></Typography>
                                  </Paper>
                                </TreeView>
                              </div>
                              : <div>{`${JSON.stringify(qa[questions.length - 1 - index])}`}</div>
                          ) :
                          <span>..thinking</span>
                        )
                      }
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default Ask
