import React, { Component } from 'react'
import { BrowserRouter , Route ,HashRouter as Router} from 'react-router-dom'
import MetaTags from 'react-meta-tags';
import Sidebar from './components/Sidebar'
import Main from './components/Main'
import Snackbar from '@material-ui/core/Snackbar'
import queryString from 'query-string'


import Data from './Data'

class App extends Component {

  constructor(props){
    super(props)
    this.state={}
    this.state.loading=true;
    this.state.loading_message="Loading Data";
  }
  componentDidMount(){
    Data.retrieveRansomwareEvents(this.loadingComplete)
    let values = queryString.parse(window.location.href.split('?')[1]||"")
    console.log(`[-] visiting URL is ${window.location.href}`)
    if (values.id_token){ 
      window.sessionStorage.setItem('token',values.id_token)
      window.sessionStorage.setItem('refresh_token',values.refresh_token)
      window.sessionStorage.setItem('id_token',values.id_token)
      window.sessionStorage.setItem('access_token',values.access_token)
    } else {
      let turl = window.sessionStorage.getItem('url') || ""
      values = queryString.parse(turl.split('#')[1]||"")
      console.log(`[-] original visiting URL is ${turl}`)
      if (values.id_token){ 
        window.sessionStorage.setItem('token',values.id_token)
        window.sessionStorage.setItem('refresh_token',values.refresh_token)
        window.sessionStorage.setItem('id_token',values.id_token)
        window.sessionStorage.setItem('access_token',values.access_token)
        }
    }

    const endpoint=window.localStorage.getItem('apiEndpoint');
    if (!endpoint || endpoint==''){
      window.localStorage.setItem('apiEndpoint',"")
    }
    
  }

  loadingComplete=(data,err)=>{
    this.setState({loading:false});
    console.info('data loading complete')

  }

  render() {


    return (
      <div className="wrapper">
        <Snackbar
          anchorOrigin={{ 
            vertical: 'top',
            horizontal: 'center'
          }}
          open={this.state.loading}
          onClose={ ()=>{} }
          message={this.state.loading_message}
        />
        
        <Router>
          <Sidebar />
          <Route path='/' render={(props) => ( <Main loading={this.state.loading} />  )} /> 
        </Router>
      </div>
    )
  }
}

export default App
