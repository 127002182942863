import React, { Component } from 'react'
import Plot from 'react-plotly.js';
import { NavLink, Link } from 'react-router-dom'


class Dashboard extends Component {

  constructor(props) {
    super(props)
    this.state = {}
    this.loadRansomwareEvents()
    this.state.loaddata = false;
  }

  componentDidMount = () => {
    this.loadRansomwareEvents()
  }
  compoentDidUnMount = () => {
  }

  loadRansomwareEvents = () => {
    console.info('loading events in dashboard')
    var events = JSON.parse(window.sessionStorage.getItem('events') || "[]")
    console.info("retrieved " + events.length + " events ")
    var sectors = events.map(e => e.sector || 'Unspecified');
    var actors = events.map(e => e.actor);
    var countries = events.map(e => e.country || 'Unspecified');
    var sectorsCount = {}
    var actorsCount = {}
    var countryCount = {}
    sectors.forEach(s => {
      if (sectorsCount[s]) { sectorsCount[s] += 1 } else { sectorsCount[s] = 1 }
    })
    actors.forEach(s => {
      if (actorsCount[s]) { actorsCount[s] += 1 } else { actorsCount[s] = 1 }
    })
    countries.forEach(s => {
      if (countryCount[s]) { countryCount[s] += 1 } else { countryCount[s] = 1 }
    })
    let sectorPie = {
      labels: [], //Object.keys(sectorsCount),
      series: []  //Object.values(sectorsCount)
    }
    let actorPie = {
      labels: [], //Object.keys(actorsCount),
      series: []  //Object.values(actorsCount)
    }
    let countryPie = {
      labels: [], //Object.keys(actorsCount),
      series: []  //Object.values(actorsCount)
    }
    // sorted sector
    var tmp_sector = []
    for (let k in sectorsCount) { tmp_sector.push({ s: k, c: sectorsCount[k] }) }
    tmp_sector = tmp_sector.sort((a, b) => (a.c < b.c) ? 1 : ((b.c < a.c) ? -1 : 0))
    tmp_sector.forEach(e => { sectorPie.labels.push(e.s); sectorPie.series.push(e.c) })

    //sectorPie.labels.forEach(k=>{ sectorPie.series.push(sectorsCount[k]) })
    var tmp_actor = []
    for (let k in actorsCount) { tmp_actor.push({ s: k, c: actorsCount[k] }) }
    tmp_actor = tmp_actor.sort((a, b) => (a.c < b.c) ? 1 : ((b.c < a.c) ? -1 : 0))
    tmp_actor.forEach(e => { actorPie.labels.push(e.s); actorPie.series.push(e.c) })
    //actorPie.labels.forEach(k=>{ actorPie.series.push(actorsCount[k]) })
    var tmp_country = []
    for (let k in countryCount) { tmp_country.push({ s: k, c: countryCount[k] }) }
    tmp_country = tmp_country.sort((a, b) => (a.c < b.c) ? 1 : ((b.c < a.c) ? -1 : 0))
    tmp_country.forEach(e => { countryPie.labels.push(e.s); countryPie.series.push(e.c) })


    let tl = events.map(e => {
      return { date: e.date.split('T')[0], sector: e.sector, actor: e.actor }
    })
    var timelineCount = {};
    var timelineSectorCount = {};
    var timelineActorCount = {};
    tl.sort((x, y) => { x.date < y.date })
      .forEach(t => {
        if (timelineCount[t.date]) { timelineCount[t.date] += 1 } else { timelineCount[t.date] = 1 }

        if (timelineSectorCount[`${t.date}_${t.sector || 'Unspecified'}`]) { timelineSectorCount[`${t.date}_${t.sector || 'Unspecified'}`] += 1 } else { timelineSectorCount[`${t.date}_${t.sector || 'Unspecified'}`] = 1 }

        if (timelineActorCount[`${t.date}_${t.actor || 'Unspecified'}`]) { timelineActorCount[`${t.date}_${t.actor || 'Unspecified'}`] += 1 } else { timelineActorCount[`${t.date}_${t.actor || 'Unspecified'}`] = 1 }
      })

    this.state.sectorPie = sectorPie
    this.state.actorPie = actorPie
    this.state.countryPie = countryPie
    this.state.revents = events
    this.state.timeline = timelineCount;
    this.state.timelineSector = timelineSectorCount;
    this.state.timelineActor = timelineActorCount;
  }

  calculateDateDifference=(dateArray)=> {
    if (dateArray.length === 0) {
      return "N/A";
    }
    const dates = dateArray.map(item => new Date(item.date));
    dates.sort((a, b) => a - b);
    const timeDifference = dates[dates.length - 1] - dates[0];
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return `${daysDifference} days`;
  }

  render() {

    if (this.props.loading) { return <div></div> }
    else { this.loadRansomwareEvents() }

    let dataPie = this.state['sectorPie']
    let dataPie2 = this.state['actorPie']

    let dataMap1 = this.state['countryPie']

    let dataTimeline = {
      labels: [],
      series: []
    }
    let dataSector = {
      date: [],
      sector: [],
      size: []
    }
    let dataActor = {
      date: [],
      actor: [],
      size: []
    }
    let actorTraces = {}
    Object.keys(this.state['timeline'])
      .sort()
      .forEach(d => {
        dataTimeline.labels.push(d);
        dataTimeline.series.push(this.state['timeline'][d])
      })
    Object.keys(this.state['timelineSector'])
      .sort()
      .map(x => {
        return { date: x.split('_')[0], sector: x.split('_')[1], key: x }
      })
      .forEach(d => {
        if (!d.sector || d.sector == "NA") { return }
        dataSector.date.push(d.date)
        dataSector.sector.push(d.sector)
        dataSector.size.push(this.state['timelineSector'][d.key])
      })
    Object.keys(this.state['timelineActor'])
      .sort()
      .map(x => {
        return { date: x.split('_')[0], actor: x.split('_')[1], key: x }
      })
      .forEach(d => {
        dataActor.date.push(d.date)
        dataActor.actor.push(d.actor)
        dataActor.size.push(this.state['timelineActor'][d.key])
        if (!actorTraces[d.actor]) { actorTraces[d.actor] = [] }
        actorTraces[d.actor].push(d.date)
      })

    return (
      <div className="content">
        <div className="container-grid">

          <div className="row">
            <div className="col-4">
              <div className="text-center">
              <img className="h-25 rounded" style={{ marginLeft: '20px', width: '470px', height: 'auto' }} src="man.png" alt="" />
              </div>
            </div>
            <div className="col-8">
              <div className="card-body text-white font-weight-bold"><h2><b>Experience the Double Extortion Platform's Dashboard</b></h2 ></div>
              <div className="card-body text-white ">
                Our interactive dashboard distills the landscape of cyber extortion events indexed across recent years.
                Fueling this dashboard is the power of the <b><Link to='/datatable'>lite version (last {`${this.state.revents.length}`} events, limited details)</Link></b> of our comprehensive cyber extortion dataset. This resource is generously offered for <b>non-commercial</b> use under the <a href="https://creativecommons.org/licenses/by-nc-sa/4.0">CC BT-CN-SA 4.0</a> license. 
              </div>

              <div className="card-body text-white"><h3><b>Unveiling Cyber Extortion: The Dual-Edged Cyberattack.</b></h3 ></div>
              <div className="card-body text-white">
                Cyber extortion, often termed as double extortion attacks, represents a formidable cyber threat landscape. In this breed of cyberattack, malevolent actors infiltrate a victim's network, pilfer sensitive data, and wield a two-pronged threat – either releasing it to the public or encrypting it, all while demanding a ransom for safe retrieval.
              </div>
              <h3 className="card-body text-white "><b>Is There More?</b></h3>
              <div className="card-body text-white">
              Absolutely, beyond the <b>Comprehensive</b> Extortion dataset, we offer an array of <b>additional</b> Cyber Intelligence datasets tailored for business decision makers.
              </div>
              
            </div>

          </div>

          <div className="row">
            <div className="col">
             
            </div>
          </div>
          <div className="row">
            <div className="col">

              <div className="col">
                <div className='d-flex justify-content-center'>
                  <a href="https://auth.eu-ep1.doubleextortion.com/login?client_id=49g8542gda2lstovq74aii323t&response_type=token&scope=email+openid+phone+profile&redirect_uri=https://app.doubleextortion.com/" target='blank'>
                    <button className="button-53 text-uppercase"><b>LOGIN TO THE PLATFORM</b></button>
                  </a>
                </div>
              </div>

            </div>
          </div>
          <br /><br />
          <div className="row">

            <div className="col-md lg">

              <div className="card">
                <div className="card-header ">
                  <h4 className="card-title">Top 10 Threatened Industries (Last {`${this.calculateDateDifference(this.state.revents)}`})</h4>
                  <p className="card-category">Victims</p>
                </div>
                <div className="card-body">
                  <Plot
                    className="w-100"
                    data={[
                      {
                        name: 'sectors',
                        values: dataPie.series.slice(0, 10),
                        labels: dataPie.labels.slice(0, 10),
                        type: 'pie',
                        colorscale: 'Prism',
                        color: 'Prism',
                        pull: 0.05,
                        automargin: true,
                        marker: {
                          line: { width: 0.5 },
                          colors:
                            ["#fff7ec", "#fff7ec", "#fff7ec", "#fee8c8", "#fdd49e", "#fdbb84", "#fc8d59", "#ef6548", "#d7301f", "#b30000", "#7f0000"].reverse()
                              .map(hex => {
                                var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
                                return result ? `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)})` : '#000';
                              })
                        }
                      },
                    ]}
                    layout={{
                      autosize: true, font: { size: 10 }, showlegend: true,
                      legend: { xanchor: 'center', x: 0.5, orientation: 'h' }
                    }}
                    config={{ responsive: true, displayModeBar: false, modeBarButtonsToRemove: ['pan2d', 'select2d', 'lasso2d', 'resetScale2d', 'zoomOut2d'] }}
                  />
                  <hr />
                  <small className="text-center">The Industry Vertical plot shows the distribution of events among the top 10 impacted business sectors.<br />
                    Currently we observed <b>{this.state.revents.length}</b> events across <b>{dataPie.labels.length}</b> industries. </small>
                </div>
              </div>
            </div>

            <div className="col-md lg">

              <div className="card">
                <div className="card-header ">
                  <h4 className="card-title">Impacted Countries (Last {`${this.calculateDateDifference(this.state.revents)}`})</h4>
                  <p className="card-category">Victims</p>
                </div>
                <div className="card-body">

                  <Plot
                    className="w-100"
                    data={[
                      {
                        type: 'choropleth',
                        locationmode: 'country names',
                        locations: dataMap1.labels,
                        z: dataMap1.series,
                        autocolorscale: false,
                        reversescale: true,

                        colorscale: [
                          [0.0, '#b30000'], [0.4, '#fc8d59'], [0.75, '#fdd49e'],
                          [0.8, '#fdd49e'], [0.9, '#fdd49e'],
                          [0.95, '#fee8c8'], [1, '#fff7ec']],
                        colorbar: {

                          title: 'Extortions',
                          thickness: 25
                        },
                      },

                    ]}
                    layout={{
                      autosize: true, font: { size: 10 }, showlegend: false,
                      geo: {

                        projection: {
                          type: 'orthographic'
                        }
                      },

                    }}
                    config={{
                      responsive: true,
                      displayModeBar: false,
                      modeBarButtonsToRemove: ['pan2d', 'select2d', 'lasso2d', 'resetScale2d', 'zoomOut2d']
                    }}
                  />

                  <hr />
                  <small className="text-center">The Impacted Countries plot shows the distribution of events among the impacted nations.<br />
                    Currently we observed <b>{this.state.revents.length}</b> events across <b>{dataMap1.labels.length}</b> countries. </small>
                </div>
              </div>
            </div>

            <div className="col-md lg">
              <div className="card">
                <div className="card-header ">
                  <h4 className="card-title">Top 10 Threat Groups (Last {`${this.calculateDateDifference(this.state.revents)}`})</h4>
                  <p className="card-category">Attacks</p>
                </div>
                <div className="card-body">
                  <Plot
                    className="w-100 mx-auto"

                    data={
                      [
                        {
                          name: 'actors',
                          values: dataPie2.series.slice(0, 10),
                          labels: dataPie2.labels.slice(0, 10),
                          type: 'pie',
                          mode: 'markers',
                          marker: {
                            line: { width: 0.5 },
                            colors:
                              ["#fff7ec", "#fff7ec", "#fff7ec", "#fee8c8", "#fdd49e", "#fdbb84", "#fc8d59", "#ef6548", "#d7301f", "#b30000", "#7f0000"].reverse()
                                .map(hex => {
                                  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
                                  return result ? `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)})` : '#000';
                                })
                          },
                          pull: 0.05,
                          automargin: true
                        }
                      ]}
                    layout={{
                      autosize: true, font: { size: 10 },
                      showlegend: true,
                      legend: { xanchor: 'center', x: 0.5, orientation: 'h' }
                    }}
                    config={{ responsive: true, displayModeBar: false, modeBarButtonsToRemove: ['pan2d', 'select2d', 'lasso2d', 'resetScale2d', 'zoomOut2d'] }}
                  />
                  <hr />
                  <small className="text-center">The Actor Statistics plot shows the distribution of the top 10 threat actor or affiliaiton program causing most of the observed attacks.<br />
                    Currently we observed <b>{dataPie2.labels.length}</b> actors attacking <b>{dataPie.labels.length}</b> industries. </small>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">

              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Extortion and Breaches Events (Last {`${this.calculateDateDifference(this.state.revents)}`})</h4>
                  <p className="card-category">Attacks</p>
                </div>
                <div className="card-body ">

                  <Plot
                    className="w-100"

                    data={[
                      {
                        name: 'events',
                        x: dataTimeline.labels.map(t => new Date(t)),
                        y: dataTimeline.series,
                        type: 'scatter',
                        colorscale: 'D3',
                        automargin: true,
                        fill: 'tozeroy',
                        marker: { line: { width: 0.5 } }
                      },
                    ]}
                    layout={{
                      autosize: true, font: { size: 10 }, showlegend: true,
                      legend: { xanchor: 'center', x: 0.5, orientation: 'h' }
                    }}
                    config={{ responsive: true, displayModeBar: false, modeBarButtonsToRemove: ['pan2d', 'select2d', 'lasso2d', 'resetScale2d', 'zoomOut2d'] }}
                  />

                </div>
                <div className="card-footer ">
                  <hr />
                  <small className="text-center">The Extortion and Breach events plot shows the evolution of double extortion events operated by threat actors during the time.
                    Currently we observed <b>{this.state.revents.length}</b> events distributed among <b>{dataTimeline.series.length}</b> days. </small>

                </div>
              </div>

            </div>
          </div>


          <div className="row">
            <div className="col">

              <div className="card">
                <div className="card-header ">
                  <h4 className="card-title">Extortions per Industry over Time (Last {`${this.calculateDateDifference(this.state.revents)}`})</h4>
                  <p className="card-category">Victims</p>
                </div>
                <div className="card-body" >

                  <Plot
                    className="w-100"
                    style={{

                    }}
                    data={[
                      {
                        x: dataSector.date,
                        y: dataSector.sector,
                        texttemplate: 'Day: %{x|%A}"',
                        mode: 'markers',
                        type: 'scatter',

                        marker: {
                          size: dataSector.size.map(x => x * 7),
                          color: dataSector.size.map(x => x * 7),
                          line: { color: '#444', width: '1px' }
                        },

                      }
                    ]}
                    layout={{
                      autosize: true, font: { size: 12 }, hovermode: true,
                      yaxis: {
                        autorange: 'reversed', gridcolor: 'darkGrey',
                        tickangle: -45,
                        fixedrange: false,
                        constrain: "domain",
                        //range:new Set(dataSector.sector).size-1
                      }
                    }}
                    config={{ responsive: true, displayModeBar: false, modeBarButtonsToRemove: ['pan2d', 'select2d', 'lasso2d', 'resetScale2d', 'zoomOut2d'] }}
                  />

                </div>
                <div className="card-footer">
                  <hr />
                  <small className="text-center">The Extortion per Industry over time plot shows the evolution of double extortion attacks grouped by affected industry sector. </small>
                </div>
              </div>

            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header ">
                  <h4 className="card-title">Actor Activities over Time (Last {`${this.calculateDateDifference(this.state.revents)}`})</h4>
                  <p className="card-category">Attack</p>
                </div>
                <div className="card-body " >

                  <Plot
                    style={{

                    }}
                    data={
                      Object.keys(actorTraces)
                        .map(a => {
                          return {
                            type: 'scatter',
                            scalemode: "count",
                            points: "all",
                            pointpos: 0.45,
                            box: { visible: true },
                            marker: {
                              line: {
                                width: 1.3,
                                color: "black"
                              },
                              symbol: "line-ns"
                            },
                            name: a, x: actorTraces[a]
                          }
                        })
                    }
                    layout={{ autosize: true, font: { size: 10 }, hovermode: false, colorscale: 'D3' }}
                    config={{ responsive: true, displayModeBar: false, modeBarButtonsToRemove: ['pan2d', 'select2d', 'lasso2d', 'resetScale2d', 'zoomOut2d'] }}
                  />
                </div>

                <div className="card-footer w-100">
                  <hr />
                  <small className="text-center">The Actor Activities over time plot reports the observed double extortion attempts grouped by threat actor or affiliation program. </small>

                </div>
              </div>

              <div className="card-body text-white text-center">
                This is a <b>light version</b> of the cyber extortion dataset available through the platform and is freely provided for <b>non-commercial</b> purposes under <a href="https://creativecommons.org/licenses/by-nc-sa/4.0">CC BT-CN-SA 4.0</a>.
              </div>
              <div className='d-flex justify-content-center'>
                <a href="https://auth.eu-ep1.doubleextortion.com/login?client_id=49g8542gda2lstovq74aii323t&response_type=token&scope=email+openid+phone+profile&redirect_uri=https://app.doubleextortion.com/" target='blank'>
                  <button className="button-53 text-uppercase"><b>LOGIN TO THE PLATFORM</b></button>
                </a>
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default Dashboard
