import React, { Component } from 'react'
import { Link,NavLink } from 'react-router-dom'
import Iubenda from 'react-iubenda-policy'

import IconButton from '@material-ui/core/IconButton';

import Icon from '@material-ui/core/Icon';
/*
            <p className="text-center" style={{'textAlign':'center'}}>
            <IconButton href="https://github.com/luca-m/" target="_blank" >
              <Icon className="fa fa-github" />
            </IconButton>
            <IconButton href="https://www.linkedin.com/in/allemco/" target="_blank" >
              <Icon className="fa fa-linkedin" />
            </IconButton>
            </p>
 * */
class Footer extends Component {
  render() {
    let token= window.sessionStorage.getItem('token') || null
    let refresh_token= window.sessionStorage.getItem('refresh_token') || null
    let id_token= window.sessionStorage.getItem('id_token') || null
    return (
      <footer className="footer">
        <div className="container-fluid">
            

          <div className="row">
            <div className="col"> 
              <nav >
                <ul className="footer-menu bg-transparent">
                <li className="nav-item ">
                  <NavLink className="nav-link" to='/land'>
                    <p>Home</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to='/dashboard'>
                    <p>Dashboard</p>
                  </NavLink>
                </li>

                <li className="nav-item ">
                  <NavLink className="nav-link" to='/datatable'>
                    <p>Public Data Table</p>
                  </NavLink>
                </li>
                {( 
                    token?
                    <li className="nav-item">
                    <NavLink className="nav-link" to='/profile'>
                      <p>Profile</p>
                    </NavLink>
                  </li>
                        :
                  <li className="nav-item">
                  <a className="nav-link" href='https://auth.eu-ep1.doubleextortion.com/login?client_id=49g8542gda2lstovq74aii323t&response_type=token&scope=email+openid+phone+profile&redirect_uri=https://doubleextortion.com/'>
                    <p>Login</p>
                  </a>
                  </li>
          )}
          {( 
                    token?<div></div>
                  :
                  <li className="nav-item">
                  <a className="nav-link" href='https://us1.list-manage.com/contact-form?u=c51dfebb743a50385261dba65&form_id=a53ccc372eb7f62abd40402bae4b47c1'>
                    <p>Get in Touch</p>
                  </a>
                  </li>
          )}

                </ul>              
              </nav>
              
            </div>

            <div className='row'>
              <div className='col align-self-end'>
                <div className='col align-self-end"'>
                  <Iubenda id='77214352' type='privacy' styling='black'>Privacy Policy</Iubenda>
                </div>
                <div className='col align-self-end"'>
                  <Iubenda id='77214352' type='cookie' styling='black'>Cookie Policy </Iubenda>
                </div>
              </div>
            </div>


          </div>


          
          <br/>
          <div className="row">
            <div className="col">
              <div className="copyright text-center">
                <span className='text-white'>	&copy; {new Date().toISOString().split('-')[0]} doubleextortion.com  | Patent Reg.ID 102023000028047 {window.sessionStorage.getItem('token')?'| Digital Intelligence Lab Srl':''} </span>
              </div>
            </div>

          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
